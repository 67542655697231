import { Typography, Card, Collapse, type CollapseProps } from 'antd';
import { useMemo } from 'react';
import { MAIN_COLOR } from '../../../../shared/const/envVariables';
import S from './style.module.css';

interface Props {
  data: any;
  index: number;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  cardTitles: string[] | null | React.ReactNode[];
  excludeRows: string[];
  collapsedRows?: string[];
  toggleSize?: (index: number) => void;
  collapsed?: boolean;
}

export const CardItem: React.FC<Props> = ({
  data,
  index,
  style,
  onClick,
  cardTitles,
  excludeRows,
  collapsedRows,
  toggleSize,
  collapsed,
}) => {
  const items: CollapseProps['items'] = useMemo(
    () => [
      {
        key: index,
        label: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {collapsedRows?.map((item) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 10,
                }}
                key={item}
              >
                <Typography.Text strong className={S.titleText}>
                  {item}
                </Typography.Text>
                <span className={S.valueText}>{data[item]}</span>
              </div>
            ))}
          </div>
        ),
        children: (
          <div
            onClick={() => {
              if (data && onClick) onClick(data);
            }}
            style={{
              cursor: onClick ? 'pointer' : 'default',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {Object.entries(data).map((item, index) => {
              const shouldExclude = excludeRows.find(
                (excludeItem) => excludeItem === item[0],
              );
              if (shouldExclude) return;

              return (
                <div
                  key={item[0]}
                  style={{
                    padding: 10,
                    borderBottom:
                      index < Object.entries(data).length - 1
                        ? '1px solid #D8D9DA'
                        : '',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 10,
                  }}
                >
                  <Typography.Text strong className={S.titleText}>
                    {item[0]}
                  </Typography.Text>
                  <span className={S.valueText}>
                    {item[1] as React.ReactNode}
                  </span>
                </div>
              );
            })}
          </div>
        ),
      },
    ],
    [collapsedRows, data, excludeRows, index, onClick],
  );
  if (collapsed) {
    return (
      <div style={{ marginBottom: 20 }}>
        <Collapse
          accordion
          items={items}
          onChange={(key) => toggleSize && toggleSize(Number(key))}
        />
      </div>
    );
  }

  return (
    <Card
      style={style}
      title={
        cardTitles && (
          <Typography.Text strong style={{ color: MAIN_COLOR }}>
            {cardTitles[index]}
          </Typography.Text>
        )
      }
      key={`card${data?.[index]?.key}`}
      className={S.cardContainer}
      onClick={onClick}
    >
      {Object.entries(data).map((item: [string, any]) => {
        const shouldExclude = excludeRows.find(
          (excludeItem) => excludeItem === item[0],
        );
        if (shouldExclude) return;

        return (
          <Card.Grid key={`cardrow${item}`} hoverable={false} id={S.cardGrid}>
            <Typography.Text strong className={S.titleText}>
              {item[0]}
            </Typography.Text>
            <span className={S.valueText}>{item[1]}</span>
          </Card.Grid>
        );
      })}
    </Card>
  );
};
