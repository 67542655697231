import { useEffect, useState } from 'react';

export function useAsyncData<
  F extends (...args: Parameters<F>) => Promise<Awaited<ReturnType<F>>>,
  T = Awaited<ReturnType<F>>,
>(fn: F, ...args: Parameters<F>): [T | null, boolean, any] {
  const [value, setValue] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);

    fn(...args)
      .then((data) => {
        setValue(data);
        setError(null);
      })
      .catch(setError)
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, args);

  return [value, isLoading, error];
}
