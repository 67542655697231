import { Button, Input, List } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { chatApi } from '../../../../../services';
import {
  ChatMessageModel,
  GetMessagesResponse,
} from '../../../../../types/entities';
import { ChatMessage } from '../ChatMessage/ChatMessage';
import S from './style.module.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loading } from '../../../../../components/Loading/Loading';
import { useAppDispatch } from '../../../../../hooks/hooks';
import { actions as alertActions } from '../../../../../stores/alert/slice';

interface Props {
  roomId: string;
}

const MESSAGES_COUNT = 50;

export const Chat: React.FC<Props> = ({ roomId }) => {
  const dispatch = useAppDispatch();
  const socket = useRef<WebSocket>();
  const responseDetails = useRef<Omit<GetMessagesResponse, 'results'>>({
    count: 0,
    next: null,
    previous: null,
  });
  const [value, setValue] = useState('');
  const [messages, setMessages] = useState<ChatMessageModel[]>([]);
  const token = localStorage.getItem('_auth');

  useEffect(() => {
    socket.current = new WebSocket(
      `wss://mware.appservice.dev/ws/chat/${roomId}/?token=${token}`,
    );

    socket.current.onopen = async () => {
      const res = await chatApi.getAllChatMessages(roomId, MESSAGES_COUNT);
      setMessages(res?.data?.results || []);
      responseDetails.current = {
        count: res?.data.count,
        next: res?.data.next,
        previous: res?.data.previous,
      };
    };

    socket.current.onmessage = async () => {
      const res = await chatApi.getAllChatMessages(roomId, MESSAGES_COUNT);

      setMessages(res?.data?.results || []);
      responseDetails.current = {
        count: res?.data.count,
        next: res?.data.next,
        previous: res?.data.previous,
      };
    };

    socket.current.onerror = () => {
      dispatch(
        alertActions.addAlert({
          type: 'error',
          message: 'Something went wrong',
        }),
      );
    };
    return () => socket.current?.close();
  }, [dispatch, roomId, token]);

  const sendMessage = async () => {
    if (!value) return;

    socket.current?.send(
      JSON.stringify({ type: 'chat.message', content: value }),
    );
    setValue('');
  };

  const getNext = async () => {
    try {
      const res = await chatApi.getAllChatMessages(
        roomId,
        MESSAGES_COUNT,
        messages.length + MESSAGES_COUNT,
      );
      if (res) setMessages((prev) => [...prev, ...res.data.results]);
      responseDetails.current = {
        count: res?.data.count,
        next: res?.data.next,
        previous: res?.data.previous,
      };
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={S.container}>
      <div className={S.chat} id="scrollableDiv">
        <InfiniteScroll
          dataLength={messages.length}
          next={getNext}
          hasMore={Boolean(responseDetails.current.next)}
          loader={<Loading size="default" />}
          scrollableTarget="scrollableDiv"
          inverse
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
        >
          <List
            dataSource={messages}
            renderItem={(item: ChatMessageModel) => (
              <ChatMessage data={item} key={item.id} />
            )}
          />
        </InfiniteScroll>
      </div>
      <div>
        <Input
          onChange={(e) => setValue(e.target.value)}
          placeholder={'Type your message...'}
          style={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            padding: '12px 16px',
            display: 'block',
          }}
          value={value}
          onKeyDown={(e) => {
            if (e.code === 'Enter') sendMessage();
          }}
        />
        <Button
          onClick={sendMessage}
          style={{
            width: '100%',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            padding: '12px 16px',
            height: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
            color: '#A3A5A8',
            backgroundColor: '#F2F2F3',
            border: '1px solid #d9d9d9',
          }}
        >
          {'Send'}
        </Button>
      </div>
    </div>
  );
};
