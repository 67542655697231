import { DownOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useState } from 'react';
import S from './style.module.css';

interface Props {
  label: string | React.ReactNode;
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  noBorder?: boolean;
  defaultText?: boolean;
  titleStyle?: React.CSSProperties;
}

export const Toggler: React.FC<React.PropsWithChildren & Props> = ({
  children,
  label,
  containerProps,
  noBorder,
  defaultText,
  titleStyle,
}) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div
      {...containerProps}
      style={{
        padding: '12px 0px',
        borderBottom: noBorder ? '' : '1px solid #d8d9da',
        ...containerProps?.style,
      }}
    >
      {!defaultText ? (
        <Typography.Title
          className={S.title}
          style={titleStyle}
          level={5}
          onClick={() => setToggle((prev) => !prev)}
        >
          {label}
          <DownOutlined
            rev={undefined}
            style={{ color: '#898C90' }}
            className={toggle ? S.iconOpened : S.iconClosed}
          />
        </Typography.Title>
      ) : (
        <Typography.Text
          className={S.title}
          style={titleStyle}
          onClick={() => setToggle((prev) => !prev)}
        >
          {label}
          <DownOutlined
            rev={undefined}
            style={{ color: '#898C90' }}
            className={toggle ? S.iconOpened : S.iconClosed}
          />
        </Typography.Text>
      )}
      {toggle && children}
    </div>
  );
};
