export const ENDPOINTS = {
  SIGN_UP: '/api/auth/users/',
  LOG_IN: '/api/auth/jwt/create/',
  REFRESH_TOKEN: '/api/auth/jwt/refresh/',
  GET_USER_DATA: '/api/auth/users/me/',
  RESET_PASSWORD: '/api/auth/users/reset_password/',
  RESET_PASSWORD_CONFIRM: '/api/auth/users/reset_password_confirm/',
  UPLOAD_FILE: '/api/files/',
  SET_NEW_PASSWORD: '/api/auth/users/set_password/',
  EDIT_USER: '/api/auth/users/',
  VERIFY_TOKEN: '/api/auth/jwt/verify/',
  GET_ALL_CHAT_MESSAGES: '/api/rooms/{room_id}/messages/',
};

export const BPM_ENDPOINS = {
  GET_BPM_TOKEN: '/token',
  CREATE_TICKET: '/api/data/fcSupportTicket',
};
