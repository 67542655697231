import { Modal, Typography, Upload, type UploadFile } from 'antd';
import { Container, MainButton } from '../../../components';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import { useState } from 'react';
import { type UploadChangeParam } from 'antd/es/upload';
import { UserApi } from '../../../services/user/api';
import S from './style.module.css';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { userActions } from '../../../stores/user/slice';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { actions as alertActions } from '../../../stores/alert/slice';
import { AxiosError } from 'axios';
import * as authSelectors from '../../../stores/user/selectors';

export const UploadAvatar = () => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [modalLoading, setmodalLoading] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const userData = useAppSelector(authSelectors.getUserData);

  const onChange = (params: UploadChangeParam<UploadFile<any>>) => {
    if (params.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    setLoading(false);
  };

  const onUpload = async (options: RcCustomRequestOptions) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', options.file);

      const uploadedFile = await UserApi.uploadFile(formData);

      await UserApi.editUserData({
        id: uploadedFile.data[0].user,
        data: { avatar: uploadedFile.data[0].id },
      });

      const editedUser = await UserApi.getUserData();

      dispatch(userActions.setUserData(editedUser.data));
      dispatch(
        alertActions.addAlert({
          message: 'Avatar has been uploaded successfully',
          type: 'success',
        }),
      );
      setLoading(false);
    } catch (error) {
      dispatch(
        alertActions.addAlert({
          message: 'Avatar upload has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error.response?.status : '',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setmodalLoading(true);
      const editedUser = await UserApi.editUserData({
        id: userData?.id,
        data: { avatar: null },
      });
      dispatch(userActions.setUserData(editedUser.data));
      dispatch(
        alertActions.addAlert({
          message: 'Avatar has been deleted successfully',
          type: 'success',
        }),
      );
      setModalOpened(false);
    } catch (error) {
      dispatch(
        alertActions.addAlert({
          message: 'Avatar deleting has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error.response?.status : '',
        }),
      );
    } finally {
      setmodalLoading(false);
    }
  };

  return (
    <>
      <Container variant="space-between" className={S.container}>
        <UserAvatar />
        <Upload
          fileList={[]}
          onChange={onChange}
          customRequest={onUpload}
          accept="image/png, image/jpeg, image/jpg"
        >
          <MainButton disabled={loading} loading={loading}>
            {'Upload new photo'}
          </MainButton>
        </Upload>

        <MainButton
          className={S.dangerButton}
          disabled={loading}
          loading={loading}
          type="default"
          danger
          onClick={() => setModalOpened(true)}
        >
          {'Delete'}
        </MainButton>
      </Container>
      <Modal
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
        }}
        centered
        confirmLoading={modalLoading}
        closable={false}
        okText={'Delete'}
        open={modalOpened}
        onCancel={() => setModalOpened(false)}
        onOk={onDelete}
        cancelButtonProps={{
          htmlType: 'button',
          type: 'default',
          style: { width: 220 },
          size: 'large',
        }}
        okButtonProps={{
          htmlType: 'button',
          type: 'primary',
          danger: true,
          style: { width: 220 },
          size: 'large',
        }}
      >
        <Typography.Paragraph strong>
          {'Deleting avatar. Are you sure?'}
        </Typography.Paragraph>
      </Modal>
    </>
  );
};
