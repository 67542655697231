import { type AxiosResponse } from 'axios';
import { SupportTicketModel } from '../../types/entities';
import sqlRequest from '../../shared/utils/sqlRequest';

export const getTicketsBySerialNumber = async (
  serialNumber: string | undefined,
  customerNumber: string,
): Promise<AxiosResponse<SupportTicketModel[]>> => {
  return sqlRequest({
    method: 'get',
    url: '/api/DynamicSql/GetDynamicSQL',
    params: {
      Sql: `select * from [dbo].[vw_WebPortalSupportTicketDetail] where EquipmentSerial='${serialNumber}' and CustomerID='${customerNumber}'  and JobNumber is not NULL`,
    },
  });
};
