import { type RadioChangeEvent } from 'antd';
import { useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { SHOW_EQUIPMENT_RADIO_OPTIONS } from '../../../shared/const/common';
import { useAppSelector } from '../../../hooks/hooks';
import { userSelectors } from '../../../stores';
import { useGetEquipmnetByIdQuery } from '../../../stores/equipment/api';
import { AdaptiveWrapper } from '../../AdaptiveWrapper/AdaptiveWrapper';
import { EquipmentCard } from '../EquipmentCard/EquipmentCard';
import { RepairsHistory } from '../RepairsHistory/RepairsHistory';
import S from './style.module.css';

export const ShowEquipmentWrapper = () => {
  const { serialNumber } = useParams();
  const customerNumber = useAppSelector(userSelectors.getCustomerNumber);

  const { data, isLoading } = useGetEquipmnetByIdQuery({
    customerNumber,
    serialNumber,
  });
  const [checkedItem, setCheckedItem] = useState<string | null>('Ticket');

  const onChange = (e: RadioChangeEvent) => {
    setCheckedItem(e.target.value);
  };

  const setValue = useCallback(
    (value: string | null) => setCheckedItem(value),
    [],
  );

  const renderItems = useMemo(() => {
    if (checkedItem === 'Card') {
      return (
        <EquipmentCard
          loading={isLoading}
          data={data}
          style={{
            maxWidth: 'none',
            width: '100%',
            border: 'none',
            padding: '16px 0',
          }}
        />
      );
    }

    if (checkedItem === 'RepairsHistory') {
      return (
        <RepairsHistory
          serialNumber={serialNumber}
          style={{ padding: '16px 0' }}
        />
      );
    }

    return (
      <>
        <EquipmentCard loading={isLoading} data={data} />
        <RepairsHistory serialNumber={serialNumber} />
      </>
    );
  }, [checkedItem, isLoading, data, serialNumber]);

  return (
    <div className={S.container}>
      <AdaptiveWrapper
        displayBreakPoint={900}
        radioOptions={SHOW_EQUIPMENT_RADIO_OPTIONS}
        onRadioChange={onChange}
        setValue={setValue}
        defaultValue="Ticket"
        checkedItem={checkedItem}
      >
        <div style={{ display: 'flex' }}>{renderItems}</div>
      </AdaptiveWrapper>
    </div>
  );
};
