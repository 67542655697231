import { Table, type TableProps } from 'antd';
import type { AnyObject } from 'antd/es/_util/type';
import S from './style.module.css';
import { useLayoutEffect, useState, useRef } from 'react';
import { CardsList } from './components/CardsList/CardsList';
import { onTableRowClick } from '../../types/common';

interface Props {
  loading?: boolean;
  excludeRows: string[];
  displayCardsWidth?: number;
  cardTitles: string[] | null;
  itemHeight?: number;
  containerHeight?: number | string;
  virtualized?: boolean;
  onRowClick?: onTableRowClick;
  collapsedRows?: string[];
  itemExpandedHeight?: number;
  collapsed?: boolean;
}

export const AdaptiveTable = <T extends AnyObject>({
  loading,
  excludeRows,
  displayCardsWidth = 800,
  cardTitles,
  virtualized,
  itemHeight,
  containerHeight,
  collapsedRows,
  itemExpandedHeight,
  onRowClick,
  collapsed,
  ...tableProps
}: TableProps<T> & Props) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [displayCards, setDisplayCards] = useState(false);

  useLayoutEffect(() => {
    function handleResize() {
      if (
        tableRef?.current &&
        tableRef.current.offsetWidth <= displayCardsWidth
      ) {
        setDisplayCards(true);
      } else {
        setDisplayCards((prev) => (prev ? !prev : prev));
      }
    }
    handleResize();

    addEventListener('resize', handleResize);

    return () => removeEventListener('resize', handleResize);
  }, [displayCardsWidth]);

  return (
    <div
      className={S.tableContainer}
      style={{ height: displayCards ? containerHeight : 'auto' }}
      ref={tableRef}
    >
      {!displayCards ? (
        <Table
          loading={loading}
          className={S.table}
          size="middle"
          sortDirections={['ascend', 'descend']}
          rowClassName={S.tableRow}
          onRow={(record) => {
            return {
              onClick: () => onRowClick && onRowClick(record),
            };
          }}
          {...tableProps}
        />
      ) : (
        <CardsList
          data={tableProps.dataSource as T[]}
          excludeRows={excludeRows}
          cardTitles={cardTitles}
          loading={loading}
          virualized={virtualized}
          itemHeight={itemHeight}
          onClick={onRowClick}
          collapsedRows={collapsedRows || []}
          itemExpandedHeight={itemExpandedHeight}
          collapsed={collapsed}
        />
      )}
    </div>
  );
};
