import { Form, Typography } from 'antd';
import { AxiosError } from 'axios';
import { useSignIn } from 'react-auth-kit';
import { Link, useNavigate } from 'react-router-dom';
import { FormInput } from '../../../components/FormInput/FormInput';
import { INPUT_TYPES } from '../../../shared/enums/common';
import { AuthService } from '../../../services/auth/service';
import { RegisterFormFields } from '../../../types/entities';
import { CONFIG } from '../RegisterForm/form-config';
import { useState } from 'react';
import { Paths } from '../../../shared/const/route-paths';
import { EXPIRES_IN, EXPIRES_IN_REFRESH } from '../../../shared/const/common';
import { MainButton } from '../../../components';
import { useAppDispatch } from '../../../hooks/hooks';
import { userActions } from '../../../stores/user/slice';
import { actions as alertActions } from '../../../stores/alert/slice';

export const LoginForm: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const dispatch = useAppDispatch();

  const [errors, setError] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: RegisterFormFields) => {
    try {
      setLoading(true);
      const response = await AuthService.getToken({
        username: values.username,
        password: values.password,
      });

      signIn({
        token: response.data.access,
        tokenType: 'Bearer',
        refreshToken: response.data.refresh,
        expiresIn: EXPIRES_IN,
        refreshTokenExpireIn: EXPIRES_IN_REFRESH,
        authState: { username: values.username },
      });

      dispatch(userActions.setIsLoggedIn(true));
      navigate(Paths.APP.SUPPORT_TICKETS);

      setLoading(false);
    } catch (error) {
      dispatch(
        alertActions.addAlert({
          message: 'Login has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error?.response?.status : '',
        }),
      );
      setLoading(false);

      if (
        error &&
        error instanceof AxiosError &&
        error.response?.status !== 500
      ) {
        setError(Object.values(error.response?.data));
        return;
      }
    }
  };

  return (
    <Form
      form={form}
      name="login"
      onFinish={onFinish}
      layout="vertical"
      style={{ width: '100%' }}
      requiredMark={false}
    >
      <Form.Item style={{ marginBottom: 10 }}>
        <Form.ErrorList
          errors={errors?.map((err) => {
            return (
              <Typography.Text key={err} type="danger">
                {err}
              </Typography.Text>
            );
          })}
        />
      </Form.Item>

      <FormInput
        label="Username (Email)"
        name="username"
        placeholder="Enter your Username"
        rules={CONFIG.username}
      />

      <FormInput
        label="Password"
        name="password"
        placeholder="Enter your Password"
        type={INPUT_TYPES.PASSWORD}
        rules={CONFIG.password}
        style={{ marginBottom: 0 }}
      />
      <Form.Item>
        <Link to={`/${Paths.FORGOT_PASSWORD}`}>
          <MainButton
            htmlType="button"
            type="link"
            size="small"
            style={{ paddingLeft: 0, maxWidth: 150 }}
          >
            {'Forgot your password?'}
          </MainButton>
        </Link>
      </Form.Item>

      <MainButton
        disabled={loading}
        loading={loading}
        style={{ maxWidth: 320, width: '100%', marginBottom: 20 }}
      >
        {'Log in'}
      </MainButton>
    </Form>
  );
};
