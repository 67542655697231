import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BASE_DETAILS_API_URL,
  CONNECTOR_DATABASE,
  CONNECTOR_LOGIN_ID,
  CONNECTOR_PASSWORD,
} from '../../shared/const/envVariables';
import { SqlService } from '../../services';
import { EquipmentCriteria } from '../../types/common';
import { EquipmentModel } from '../../types/entities';

export const equipmentRtkApi = createApi({
  reducerPath: 'equipmentRtkApi',
  tagTypes: ['equipment'],
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({ baseUrl: BASE_DETAILS_API_URL }),
  endpoints: (builder) => ({
    getEquipments: builder.query<
      EquipmentModel[],
      { customerNumber: string | undefined; criteria?: EquipmentCriteria }
    >({
      query: ({ customerNumber, criteria }) => ({
        url: '/api/DynamicSql/GetDynamicSQL',
        params: {
          ConnectorLoginId: CONNECTOR_LOGIN_ID,
          ConnectorPassword: CONNECTOR_PASSWORD,
          Database: CONNECTOR_DATABASE,
          Sql: SqlService.getEquipments(customerNumber, criteria),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ EquipmentSerialNo }) => ({
                type: 'equipment' as const,
                id: EquipmentSerialNo,
              })),
              { type: 'equipment', id: 'LIST' },
            ]
          : [{ type: 'equipment', id: 'LIST' }],
    }),
    getEquipmnetById: builder.query<
      EquipmentModel,
      { customerNumber: string | undefined; serialNumber: string | undefined }
    >({
      query: ({ customerNumber, serialNumber }) => ({
        url: '/api/DynamicSql/GetDynamicSQL',
        params: {
          ConnectorLoginId: CONNECTOR_LOGIN_ID,
          ConnectorPassword: CONNECTOR_PASSWORD,
          Database: CONNECTOR_DATABASE,
          Sql: SqlService.getEquipmentBySerialId(customerNumber, serialNumber),
        },
      }),
      providesTags: (EquipmentSerialNo) => [
        { type: 'equipment', EquipmentSerialNo },
      ],
      transformResponse: (response: EquipmentModel[]) => response?.[0],
    }),
  }),
});

export const { useGetEquipmentsQuery, useGetEquipmnetByIdQuery } =
  equipmentRtkApi;
