import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Empty, Typography } from 'antd';
import { Container, CopyButton, Toggler } from '../../../../../components';
import { DefaultAvatar } from '../../../../../components/DefaultAvatar/DefaultAvatar';
import { MAIN_COLOR } from '../../../../../shared/const/envVariables';
import { useAppSelector } from '../../../../../hooks/hooks';
import { userSelectors } from '../../../../../stores';
import { SupportTicketModel } from '../../../../../types/entities';
import S from './style.module.css';
import dayjs from 'dayjs';
import { EMPTY } from '../../../../../shared/const/common';
import { AccessoriesTable } from '../../../AccessoriesTable/AccessoriesTable';
import { TicketJobsTable } from '../../../TicketJobsTable/TicketJobsTable';
import { Loading } from '../../../../../components/Loading/Loading';

interface Props {
  tickets: SupportTicketModel[] | undefined;
  loading?: boolean;
  style?: React.CSSProperties;
}

export const TicketCard: React.FC<Props> = ({ tickets, loading, style }) => {
  const userData = useAppSelector(userSelectors.getUserData);
  const data = tickets?.[0];

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <Empty style={{ width: '100%' }} />;
  }

  return (
    <div className={S.ticketInfo} style={style}>
      <Container variant="space-between" className={S.ticketNoStatus}>
        <CopyButton
          data={{ copyValue: data?.TicketNo?.toString() }}
          label={data?.TicketNo}
          labelProps={{ strong: true }}
        />

        <Typography.Text className={`${S.createdStatus} ${S.text}`}>
          {data?.TicketStatus || EMPTY}
        </Typography.Text>
      </Container>

      <Typography.Title level={5}>{data?.Model}</Typography.Title>
      <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
        <Typography.Text className={S.text}>
          {'Faulty Equipment Serial number:'}
        </Typography.Text>
        <CopyButton
          data={{ copyValue: data?.EquipmentSerial }}
          label={data?.EquipmentSerial}
          style={{ marginBottom: 16, display: 'flex', gap: 8 }}
        />
      </div>

      <Typography.Title level={5}>
        {'Physical condition of the faulty equipment:'}
      </Typography.Title>
      <Typography.Paragraph className={S.text}>
        {data?.EquipmentConfirmedProblem}
      </Typography.Paragraph>

      <div className={S.customerInfo}>
        <div className={S.nameNumber}>
          <Typography.Text strong>{userData?.customerName}</Typography.Text>

          <CopyButton
            data={{ copyValue: userData?.customerNumber }}
            label={userData?.customerNumber}
          />
        </div>

        <div style={{ display: 'flex', gap: 4, marginBottom: 12 }}>
          <Typography.Text
            className={S.text}
            type="secondary"
            style={{ display: 'flex', gap: 6 }}
          >
            <MailOutlined rev={undefined} />
            {`Contact email:`}
          </Typography.Text>
          <Typography.Text
            className={S.text}
            strong
            style={{ color: MAIN_COLOR }}
          >
            {userData?.email}
          </Typography.Text>
        </div>

        <div style={{ display: 'flex', gap: 4 }}>
          <Typography.Text
            className={S.text}
            type="secondary"
            style={{ display: 'flex', gap: 6 }}
          >
            <PhoneOutlined rev={undefined} />
            {`Contact phone number: `}
          </Typography.Text>
          <Typography.Text className={S.text} strong>
            {userData?.phone}
          </Typography.Text>
        </div>
      </div>

      <div className={S.personsContainer}>
        <div>
          <Typography.Paragraph className={S.text} type="secondary">
            {'Responsible Person'}
          </Typography.Paragraph>
          <div
            style={{
              display: 'flex',
              gap: 8,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <DefaultAvatar size={24} />
            <Typography.Text className={S.text}>
              {data?.TicketResponsiblePerson || EMPTY}
            </Typography.Text>
          </div>
        </div>

        <div>
          <Typography.Paragraph type="secondary" className={S.text}>
            {'Created by'}
          </Typography.Paragraph>
          <div
            style={{
              display: 'flex',
              gap: 8,
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <DefaultAvatar size={24} />
            <Typography.Text className={S.text}>
              {data?.TicketCreatedByUser || EMPTY}
            </Typography.Text>
          </div>
        </div>
      </div>
      <Toggler label={'Waranty'}>
        <div className={S.warantyContainer}>
          <div className={S.warantyItem}>
            <Typography.Text
              className={S.text}
              type="secondary"
              style={{ marginBottom: 3 }}
            >
              {'Start Date'}
            </Typography.Text>
            <Typography.Text className={S.text}>
              {dayjs(data?.EquipmentWarrantyStartDate)
                .format('DD/MM/YYYY')
                .toString()}
            </Typography.Text>
          </div>
          <div className={S.warantyItem}>
            <Typography.Text
              className={S.text}
              type="secondary"
              style={{ marginBottom: 3 }}
            >
              {'End Date'}
            </Typography.Text>
            <Typography.Text className={S.text}>
              {dayjs(data?.EquipmentWarrantyEndDate)
                .format('DD/MM/YYYY')
                .toString()}
            </Typography.Text>
          </div>
          <div className={S.warantyItem}>
            <Typography.Text
              className={S.text}
              type="secondary"
              style={{ marginBottom: 3 }}
            >
              {'Remaining Days'}
            </Typography.Text>
            <Typography.Text className={S.text}>
              {data?.EquipmentWarrantyRemainingDays}
            </Typography.Text>
          </div>
        </div>
      </Toggler>

      <Toggler label={'Reported Fault'}>
        <Typography.Text className={S.text}>
          {data?.ReportedFault || EMPTY}
        </Typography.Text>
      </Toggler>

      <Toggler label={'Accessories'}>
        <AccessoriesTable ticketData={data} />
      </Toggler>

      <Toggler
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography.Title style={{ margin: 0 }} level={5}>
              {'Jobs'}
            </Typography.Title>
            <Typography.Text
              className={S.text}
              style={{
                backgroundColor: MAIN_COLOR,
                borderRadius: 10,
                padding: '2px 8px',
                color: '#FFF',
                marginLeft: 8,
              }}
            >{`${
              tickets.filter((item) => item.JobNumber).length
            } jobs`}</Typography.Text>
          </div>
        }
        containerProps={{ style: { borderBottom: 'none' } }}
      >
        <TicketJobsTable ticketData={tickets} />
      </Toggler>
    </div>
  );
};
