import { Layout, Typography, Space } from 'antd';
import { Content, Footer } from 'antd/es/layout/layout';
import { Outlet, useLocation } from 'react-router-dom';
import { Container, Logo } from '../../components';
import S from './style.module.css';
import MwareLogo from '../../mware-logo.png';

export const CardViewLayout = () => {
  const { pathname } = useLocation();
  const isAuthRoute = pathname === '/auth';

  return (
    <Layout className={S.layout}>
      <Content
        className={S.content}
        style={{
          alignItems: isAuthRoute ? 'center' : '',
        }}
      >
        <Container variant="vertical" className={S.wrapper}>
          {!isAuthRoute ? <Logo /> : null}
          <Container className={S.papper}>
            <Outlet />
          </Container>
        </Container>
      </Content>

      <Footer className={S.footer}>
        <Space align="center">
          <Typography.Text type="secondary">Powered by</Typography.Text>
          <Logo src={MwareLogo} width={80} height={18} />
        </Space>
      </Footer>
    </Layout>
  );
};
