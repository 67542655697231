import sqlRequest from '../../shared/utils/sqlRequest';
import { VmSiteSqlData } from '../../types/profile';

export const getVmSites = async (
  customerNumber?: string,
): Promise<VmSiteSqlData[] | undefined> => {
  try {
    const response = await sqlRequest({
      method: 'get',
      url: '/api/DynamicSql/GetDynamicSQL',
      params: {
        Sql: customerNumber
          ? `SELECT TXSITENAME,TXSITE,TXSPHONE,TXSEMAIL,TXADDRESS1,TXCITY FROM VMSITE where TXCUST='${customerNumber}'`
          : 'SELECT TXSITENAME,TXSITE,TXSPHONE,TXSEMAIL,TXADDRESS1,TXCITY FROM VMSITE',
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getArcusData = async (customerNumber: string | undefined) => {
  try {
    if (!customerNumber) return;

    const response = await sqlRequest({
      method: 'get',
      url: '/api/DynamicSql/GetDynamicSQL',
      params: {
        Sql: `select * from ARCUS where IDCUST='${customerNumber}'`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
