import { Input, Select, Typography } from 'antd';
import { MainButton } from '../../../components';
import { useState, useEffect } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import S from './style.module.css';
import { type NamePath } from 'antd/es/form/interface';
import { AccessoryModel } from '../../../types/entities';

interface Props {
  setFieldValue: (name: NamePath, value: AccessoryModel[] | undefined) => void;
  accessoriesOptions: { value: string; label: string }[] | undefined;
}

export const AccessoriesList: React.FC<Props> = ({
  setFieldValue,
  accessoriesOptions,
}) => {
  const [accessories, setAccessories] = useState<
    { id: string; item: string; description: string }[] | undefined
  >(undefined);

  useEffect(() => {
    setFieldValue('accessories', accessories);
  }, [accessories, setFieldValue]);

  const onChange = (index: string, value: string) => {
    setAccessories((prev) => {
      return prev?.map((ac) => {
        if (ac.id === index) return { ...ac, description: value };
        return ac;
      });
    });
  };

  return (
    <div>
      <Typography.Title level={5} style={{ marginBottom: 16 }}>
        {'Accessories'}
      </Typography.Title>

      <div
        style={{
          border: '1px solid #D8D9DA',
          borderRadius: 5,
          marginBottom: 20,
        }}
      >
        <div className={S.titleRow}>
          <Typography.Text strong>{'№'}</Typography.Text>
          <Typography.Text strong>{'Item'}</Typography.Text>
          <Typography.Text strong>{'Description'}</Typography.Text>
        </div>
        <div>
          {accessories?.map((item) => {
            return (
              <div key={item?.id} className={S.accessoryContainer}>
                <Typography.Text strong>{item.id}</Typography.Text>

                <Select
                  placeholder="Choose item"
                  className={S.itemField}
                  options={accessoriesOptions}
                  onSelect={(value) => {
                    setAccessories((prev) => {
                      return prev?.map((ac) => {
                        if (item.id === ac.id) return { ...ac, item: value };
                        return ac;
                      });
                    });
                  }}
                />

                <Input
                  placeholder="Enter description"
                  onChange={(event) => onChange(item.id, event.target.value)}
                  className={S.descriptionField}
                />

                <MainButton
                  className={S.closeButton}
                  icon={<CloseOutlined size={15} rev={undefined} />}
                  size="middle"
                  htmlType="button"
                  style={{ background: 'none', color: '#A3A5A8' }}
                  onClick={() => {
                    setAccessories((prevValue) => {
                      const filteredAccessories = prevValue?.filter(
                        (prevItem) => prevItem.id !== item.id,
                      );
                      return filteredAccessories?.length === 0
                        ? undefined
                        : filteredAccessories;
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
        <div>
          <MainButton
            style={{ width: 144 }}
            type="link"
            htmlType="button"
            icon={<PlusOutlined rev={undefined} />}
            onClick={() =>
              setAccessories((prev) => {
                const lastItemId = Number(prev?.at(-1)?.id) || 0;
                const newList = prev
                  ? [
                      ...prev,
                      {
                        id: `${lastItemId + 1}`,
                        item: '',
                        description: '',
                      },
                    ]
                  : [
                      {
                        id: `${lastItemId + 1}`,
                        item: '',
                        description: '',
                      },
                    ];

                return newList;
              })
            }
          >
            {'Add Accessory'}
          </MainButton>
        </div>
      </div>
    </div>
  );
};
