import {
  VariableSizeList as List,
  type VariableSizeListProps,
} from 'react-window';
import AutoSizer, {
  type HorizontalSize,
  type VerticalSize,
} from 'react-virtualized-auto-sizer';
import React from 'react';

type Row = ({
  index,
  style,
}: {
  index: number;
  style: React.CSSProperties;
}) => JSX.Element;

export const VirtualizedList: React.FC<
  Omit<VariableSizeListProps<Row>, 'width' | 'height'> & React.PropsWithRef<any>
> = React.forwardRef(({ children, innerElementType, ...listProps }, ref) => {
  return (
    <AutoSizer style={{ width: '100%', height: '100%' }}>
      {({ height, width }: VerticalSize & HorizontalSize) => {
        return (
          <List
            {...listProps}
            height={height}
            width={width}
            ref={ref}
            innerElementType={innerElementType}
          >
            {children}
          </List>
        );
      }}
    </AutoSizer>
  );
});
