import { Empty } from 'antd';
import { VirtualizedList } from '../../../../components/VirtualizedList/VirtualizedList';
import { Loading } from '../../../../components/Loading/Loading';
import { CardItem } from '../CardItem/CardItem';
import React, { useState, useLayoutEffect } from 'react';
import { VariableSizeList } from 'react-window';

export type DataType<T extends Record<string, any> = Record<string, any>> =
  Readonly<T>[];

interface Props {
  data?: DataType;
  cardTitles: string[] | null | React.ReactNode[];
  excludeRows: string[];
  loading?: boolean;
  onClick?: <T>(record: T) => void;
  listHeight?: number;
  itemHeight?: number;
  itemExpandedHeight?: number;
  virualized?: boolean;
  collapsedRows?: string[];
  collapsed?: boolean;
}

export const CardsList: React.FC<Props> = ({
  data,
  cardTitles,
  excludeRows,
  onClick,
  loading,
  itemHeight,
  virualized,
  collapsedRows,
  collapsed,
  itemExpandedHeight,
}) => {
  const [sizes, setSizes] = useState<Record<string, number>>({});
  const listRef = React.createRef<VariableSizeList>();

  useLayoutEffect(() => {
    if (data) {
      const settedSizes = data.reduce((acc, item, index) => {
        const newAcc: Record<string, number> = acc;
        newAcc[index] = itemHeight || 0;
        return newAcc;
      }, {});
      setSizes(settedSizes);
    }
  }, [data, itemHeight]);

  if (loading) {
    return <Loading size="default" />;
  }
  if (!data || !data.length) {
    return <Empty style={{ width: '100%' }} />;
  }

  const Row = ({
    index,
    style,
    toggleSize,
  }: {
    index: number;
    style: any;
    toggleSize: (index: number) => void;
  }) => (
    <CardItem
      data={data[index]}
      collapsedRows={collapsedRows}
      index={index}
      style={{
        ...style,
        top: index === 0 ? style.top : Number(style.top) + index * 20,
      }}
      cardTitles={cardTitles}
      excludeRows={excludeRows}
      onClick={onClick}
      toggleSize={toggleSize}
      collapsed={collapsed}
    />
  );

  const toggleSize = (index: number) => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(index);
    }

    setSizes((prevState) => {
      return {
        ...prevState,
        [index]:
          prevState?.[index] === itemHeight ? itemExpandedHeight : itemHeight,
      };
    });
  };

  if (virualized) {
    return (
      <VirtualizedList
        ref={listRef}
        itemCount={data.length}
        itemSize={(index: number) => sizes?.[index] || itemHeight}
      >
        {(props: { index: number; style: any }) => (
          <Row {...props} toggleSize={toggleSize} />
        )}
      </VirtualizedList>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {data.map((item, index) => {
        return (
          <CardItem
            data={data[index]}
            index={index}
            cardTitles={cardTitles}
            excludeRows={excludeRows}
            key={`card${item.key}`}
            onClick={onClick}
            collapsedRows={collapsedRows}
            collapsed={collapsed}
          />
        );
      })}
    </div>
  );
};
