import { Typography } from 'antd';
import React from 'react';
import { DefaultAvatar } from '../../../../../components/DefaultAvatar/DefaultAvatar';
import {
  MAIN_COLOR,
  MAIN_COLOR_OPACITY_30,
} from '../../../../../shared/const/envVariables';
import { useAppSelector } from '../../../../../hooks/hooks';
import { userSelectors } from '../../../../../stores';
import { type ChatMessageModel } from '../../../../../types/entities';
import dayjs from 'dayjs';
import { EMPTY } from '../../../../../shared/const/common';
import S from './style.module.css';

interface Props {
  data: ChatMessageModel;
}

export const ChatMessage: React.FC<Props> = ({ data }) => {
  const currentUserId = useAppSelector(userSelectors.getUserData)?.id;
  const isMineMessage = data.user.id === currentUserId;

  return (
    <div
      style={{
        display: 'flex',
        marginBottom: 16,
        alignItems: 'flex-start',
        justifyContent: isMineMessage ? 'flex-end' : 'flex-start',
        gap: 8,
      }}
    >
      <div>{isMineMessage ? null : <DefaultAvatar size={48} />}</div>
      <div
        className={S.messageContainer}
        style={{
          backgroundColor: isMineMessage
            ? MAIN_COLOR_OPACITY_30 || MAIN_COLOR
            : '#F2F2F3',
          borderTopLeftRadius: isMineMessage ? 8 : 0,
          borderTopRightRadius: isMineMessage ? 0 : 8,
        }}
      >
        <Typography.Paragraph
          className={S.text}
          strong
          style={{ marginBottom: 8 }}
        >{`${data.user.lastName || EMPTY} ${
          data.user.firstName || EMPTY
        }`}</Typography.Paragraph>

        <div className={S.contentContainer}>
          <Typography.Text className={`${S.content} ${S.text}`}>
            {data.content}
          </Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {dayjs(data.createdAt).format('HH:mm')}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
