import { Header } from 'antd/es/layout/layout';
import { BurgerMenu } from '../BurgerMenu/BurgerMenu';
import { CountList } from '../CountList/CountList';
import { HeaderConrols } from '../HeaderControls/HeaderConrols';
import { useState } from 'react';
import S from './style.module.css';

export const LayoutHeader = () => {
  const [opened, setOpened] = useState(false);
  return (
    <Header className={S.header}>
      <div className={S.container}>
        <CountList />
        <HeaderConrols />
      </div>

      <div
        className={`${S.burgerButton} ${opened ? S.active : ''}`}
        onClick={() => setOpened(!opened)}
      >
        <span className={`${S.burgerItem} ${opened ? S.active : ''}`}></span>
        <span className={`${S.burgerItem} ${opened ? S.active : ''}`}></span>
        <span className={`${S.burgerItem} ${opened ? S.active : ''}`}></span>
      </div>
      <BurgerMenu opened={opened} setOpened={setOpened} />
    </Header>
  );
};
