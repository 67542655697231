import { useMemo } from 'react';
import { AdaptiveTable } from '../../AdaptiveTable/AdaptiveTable';
import { EMPTY } from '../../../shared/const/common';
import { VmSiteColumns } from '../../../shared/const/tableColumns';
import { useAppSelector } from '../../../hooks/hooks';
import { useAsyncData } from '../../../hooks/useAsyncData';
import { vmSitesApi } from '../../../services';
import { userSelectors } from '../../../stores';
import { VmSiteDataType, VmSiteSqlData } from '../../../types/profile';

export const VmSiteTable = () => {
  const userData = useAppSelector(userSelectors.getUserData);
  const [data, loading] = useAsyncData(
    vmSitesApi.getVmSites,
    userData?.customerNumber,
  );

  const dataSource: {
    data: VmSiteDataType[];
    exclude: string[];
    cardTitles: string[];
  } = useMemo(() => {
    if (!data) return { data: [], exclude: [], cardTitles: [] };

    return {
      data: data?.map((item: VmSiteSqlData, index: number) => {
        return {
          ['key']: String(index),
          ['vmSiteName']: item.TXSITENAME,
          ['ID']: item.TXSITE,
          ['Contact Number']: item.TXSPHONE,
          ['Contact Email']: item.TXSEMAIL,
          ['Address']: `${item.TXCITY} ${item.TXADDRESS1}`,
          ['Contact Person']: item.TXCONTACT || EMPTY,
        };
      }),
      exclude: ['key', 'vmSiteName'],
      cardTitles: data.map((item) => item.TXSITENAME),
    };
  }, [data]);

  return (
    <AdaptiveTable
      dataSource={dataSource.data}
      loading={loading}
      columns={VmSiteColumns}
      pagination={false}
      excludeRows={dataSource.exclude}
      displayCardsWidth={800}
      cardTitles={dataSource.cardTitles}
    />
  );
};
