import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Paths } from '../shared/const/route-paths';
import './App.css';
import { Auth } from '../pages/auth';
import { SignUp } from '../pages/auth/sign-up';
import { CardViewLayout } from '../layouts/CardViewLayout/CardViewLayout';
import { LogIn } from '../pages/auth/log-in';
import { ForgotPassword } from '../pages/auth/forgot-password';
import { RegistrationSuccess } from '../pages/auth/registration-success';
import { ChangePasswordSuccess } from '../pages/auth/change-password-success';
import { EditProfile } from '../pages/profile/edit-profile';
import { Profile } from '../pages/profile';
import { SupportTickets } from '../pages/support-tickets/support-tickets';
import { Jobs } from '../pages/jobs/jobs';
import { Equipment } from '../pages/equipment/equipment';
import { SideBarLayout } from '../layouts/SideBarLayout/SideBarLayout';
import { CheckYourEmail } from '../pages/auth/check-your-email';
import { Password } from '../pages/profile/password';
import { VmSites } from '../pages/profile/vm-sites';
import { ResetPasswordConfirm } from '../pages/auth/reset-password-confirm';
import {
  useAppSelector,
  useAuthenticateUser,
  useLogout,
  useUserData,
} from '../hooks/hooks';
import { ShowTicket } from '../pages/support-tickets/show-ticket';
import { CreateTicket } from '../pages/support-tickets/create-ticket';
import { Loading } from '../components/Loading/Loading';
import { PrivateRoute } from '../components/PrivateRoute/PrivateRoute';
import { userSelectors } from '../stores';
import { ShowEquipment } from '../pages/equipment/show-equipment';

export const App = () => {
  const isAuthenticated = useAppSelector(userSelectors.getIsLoggedIn);
  const loading = useAppSelector(userSelectors.getLoading);

  useUserData();
  useAuthenticateUser();
  useLogout();

  if (loading) {
    return <Loading size="large" />;
  }

  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route path={'/'} element={<CardViewLayout />}>
          <Route path={Paths.AUTH} element={<Auth />} />
          <Route path={Paths.SIGN_UP} element={<SignUp />} />
          <Route path={Paths.LOG_IN} element={<LogIn />} />
          <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={Paths.CHECK_YOUR_EMAIL} element={<CheckYourEmail />} />
          <Route
            path={Paths.RESET_PASSWORD_CONFIRM}
            element={<ResetPasswordConfirm />}
          />
          <Route
            path={Paths.REGISTRATION_SUCCESS}
            element={<RegistrationSuccess />}
          />
          <Route
            path={Paths.CHANGE_PASSWORD_SUCCESS}
            element={<ChangePasswordSuccess />}
          />

          <Route index element={<Navigate replace to={Paths.AUTH} />} />

          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>

        <Route
          path={Paths.APP.INDEX}
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <SideBarLayout />
            </PrivateRoute>
          }
        >
          <Route path={Paths.SUPPORT_TICKETS} element={<SupportTickets />} />
          <Route path={Paths.SHOW_TICKET} element={<ShowTicket />} />
          <Route path={Paths.CREATE_TICKET} element={<CreateTicket />} />

          <Route path={Paths.JOBS} element={<Jobs />} />

          <Route path={Paths.EQUIPMENT} element={<Equipment />} />
          <Route path={Paths.SHOW_EQUIPMENT} element={<ShowEquipment />} />

          <Route path={Paths.APP.PROFILE.INDEX} element={<Profile />}>
            <Route
              path={Paths.APP.PROFILE.EDIT_PROFILE}
              element={<EditProfile />}
            />
            <Route path={Paths.APP.PROFILE.PASSWORD} element={<Password />} />
            <Route path={Paths.APP.PROFILE.VM_SITES} element={<VmSites />} />
            <Route
              index
              element={<Navigate replace to={Paths.APP.PROFILE.EDIT_PROFILE} />}
            />
          </Route>

          <Route
            index
            element={<Navigate replace to={Paths.APP.SUPPORT_TICKETS} />}
          />
          <Route
            path="*"
            element={<Navigate replace to={Paths.SUPPORT_TICKETS} />}
          />
        </Route>
      </Routes>
    </React.Suspense>
  );
};
