import {
  configureStore,
  type ThunkAction,
  type Action,
} from '@reduxjs/toolkit';
import userReducer from './user/slice';
import { reducer as alertReducer } from './alert/slice';
import { equipmentRtkApi } from './equipment/api';
import { supportTicketsRtkApi } from './support-tickets/api';
import { setupListeners } from '@reduxjs/toolkit/query';

export const store = configureStore({
  reducer: {
    user: userReducer,
    alerts: alertReducer,
    [equipmentRtkApi.reducerPath]: equipmentRtkApi.reducer,
    [supportTicketsRtkApi.reducerPath]: supportTicketsRtkApi.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      equipmentRtkApi.middleware,
      supportTicketsRtkApi.middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch);
