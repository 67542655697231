import { Paths } from './route-paths';

export const EXPIRES_IN = 5;
export const EXPIRES_IN_REFRESH = 1440;

export const EMPTY = '<blank>';

export const PROFILE_NAV_LINKS = [
  {
    label: 'Edit profile',
    path: Paths.APP.PROFILE.EDIT_PROFILE,
  },
  { label: 'Password', path: Paths.APP.PROFILE.PASSWORD },
  { label: 'VM Site(s) details', path: Paths.APP.PROFILE.VM_SITES },
];

export const SHOW_TICKET_RADIO_OPTIONS = [
  { label: 'Ticket', value: 'Ticket', defaultChecked: true },
  { label: 'Chat', value: 'Chat' },
];

export const NEAREST_BRANCH_OPTIONS = [
  { label: 'Cape Town', value: 'Cape Town' },
  { label: 'Johannesburg', value: 'Johannesburg' },
  { label: 'Durban', value: 'Durban' },
];

export const SHOW_EQUIPMENT_RADIO_OPTIONS = [
  { label: 'Card', value: 'Card', defaultChecked: true },
  { label: 'Repairs History', value: 'RepairsHistory' },
];

export const JOBS_FILTERS = [
  [
    { label: 'Job Number', filterByField: 'JobNumber' },
    { label: 'Job Type', filterByField: 'JobType' },
    { label: 'Status', filterByField: 'JobStatus' },
    { label: 'Site', filterByField: 'SiteName' },
  ],
  [
    {
      label: 'Related Ticket number',
      filterByField: 'TicketNo',
    },
    {
      label: 'Faulty Equipment Serial Number',
      filterByField: 'EquipmentSerial',
    },
    {
      label: 'Faulty Equipment Device',
      filterByField: 'Model',
    },
  ],
  [
    { label: 'Fault', filterByField: 'ReportedFault' },
    { label: 'SLA Remaining Time', filterByField: 'SLARemainingTime' },
    { label: 'Job Created', filterByField: 'JobCreatedDate', date: true },
  ],
];

export const EQUIPMENT_FILTERS = [
  [
    { label: 'Equipment Device', filterByField: 'EquipmentModelDescription' },
    { label: 'Manufacturer', filterByField: 'Manufacturer' },
    { label: 'Serial Number', filterByField: 'EquipmentSerialNo' },
  ],
  [
    {
      label: 'Warranty Remaining Days',
      filterByField: 'WarrantyRemainingDays',
    },
    {
      label: 'Site Name',
      filterByField: 'SiteName',
    },
  ],
];

export const SUPPORT_TICKETS_FILTERS = [
  [
    { label: 'Status', filterByField: 'TicketStatus' },
    { label: 'Faulty Equipment Device', filterByField: 'Model' },
    { label: 'Serial Number', filterByField: 'EquipmentSerial' },
    { label: 'Fault', filterByField: 'ReportedFault' },
  ],
  [
    {
      label: 'Site Name',
      filterByField: 'SiteName',
    },
    {
      label: 'Responsible Person',
      filterByField: 'TicketResponsiblePerson',
    },
    {
      label: 'Created by',
      filterByField: 'TicketCreatedByUser',
    },
  ],
  [
    {
      label: 'Ticket Creation Date',
      filterByField: 'TicketLoggedDate',
      date: true,
    },
    {
      label: 'Warranty Start Date',
      filterByField: 'EquipmentWarrantyStartDate',
      date: true,
    },
    {
      label: 'Warranty End Date',
      filterByField: 'EquipmentWarrantyEndDate',
      date: true,
    },
  ],
];

export const physicalConditionData = [
  {
    value: 'Minor failure - Equipment is functional and non-defective',
    label: 'Minor failure - Equipment is functional and non-defective',
  },
  {
    value:
      'Minor failure - Visual inspection performed to find equipment is non-functional and defective',
    label:
      'Minor failure - Visual inspection performed to find equipment is non-functional and defective',
  },
  {
    value:
      'Minor failure - Normal wear and tear causing equipment to be non-functional and defective',
    label:
      'Minor failure - Normal wear and tear causing equipment to be non-functional and defective',
  },
  {
    value:
      'Minor failure - Useful service life of equipment has expired causing equipment to be non-functional and defective',
    label:
      'Minor failure - Useful service life of equipment has expired causing equipment to be non-functional and defective',
  },
  {
    value: ' Minor failure - Reduced performance experienced during normal use',
    label: ' Minor failure - Reduced performance experienced during normal use',
  },
  {
    value:
      'Minor failure - Malfunction occurred due to improper use by the operator',
    label:
      'Minor failure - Malfunction occurred due to improper use by the operator',
  },
  {
    value:
      'Major failure - Visual inspection performed to find equipment is non-functional and defective',
    label:
      'Major failure - Visual inspection performed to find equipment is non-functional and defective',
  },
  {
    value:
      ' Major failure - Normal wear and tear causing equipment to be non-functional and defective',
    label:
      ' Major failure - Normal wear and tear causing equipment to be non-functional and defective',
  },
  {
    value:
      ' Major failure - Useful service life of equipment has expired causing equipment to be non-functional and defective',
    label:
      ' Major failure - Useful service life of equipment has expired causing equipment to be non-functional and defective',
  },
  {
    value: 'Major failure - Reduced performance experienced during normal use',
    label: 'Major failure - Reduced performance experienced during normal use',
  },
  {
    value:
      'Major failure - Malfunction occurred due to improper use by the operator',
    label:
      'Major failure - Malfunction occurred due to improper use by the operator',
  },
  {
    value:
      ' Hazardous failure - Visual inspection performed to find equipment is non-functional and defective',
    label:
      ' Hazardous failure - Visual inspection performed to find equipment is non-functional and defective',
  },
  {
    value:
      ' Hazardous failure - Normal wear and tear causing equipment to be non-functional and defective',
    label:
      ' Hazardous failure - Normal wear and tear causing equipment to be non-functional and defective',
  },
  {
    value:
      'Hazardous failure - Useful service life of equipment has expired causing equipment to be non-functional and defective',
    label:
      'Hazardous failure - Useful service life of equipment has expired causing equipment to be non-functional and defective',
  },
  {
    value:
      ' Hazardous failure - Reduced performance experienced during normal use',
    label:
      ' Hazardous failure - Reduced performance experienced during normal use',
  },
  {
    value:
      'Hazardous failure - Malfunction occurred due to improper use by the operator',
    label:
      'Hazardous failure - Malfunction occurred due to improper use by the operator',
  },
  {
    value:
      'Catastrophic failure - Visual inspection performed to find equipment is non-functional and defective',
    label:
      'Catastrophic failure - Visual inspection performed to find equipment is non-functional and defective',
  },
  {
    value:
      'Catastrophic failure - Normal wear and tear causing equipment to be non-functional and defective',
    label:
      'Catastrophic failure - Normal wear and tear causing equipment to be non-functional and defective',
  },
  {
    value:
      ' Catastrophic failure - Useful service life of equipment has expired causing equipment to be non-functional and defective',
    label:
      ' Catastrophic failure - Useful service life of equipment has expired causing equipment to be non-functional and defective',
  },
  {
    value:
      ' Catastrophic failure - Reduced performance experienced during normal use',
    label:
      ' Catastrophic failure - Reduced performance experienced during normal use',
  },
  {
    value:
      'Catastrophic failure - Malfunction occurred due to improper use by the operator',
    label:
      'Catastrophic failure - Malfunction occurred due to improper use by the operator',
  },
  {
    value: 'Other - Please describe the condition in the Description box below',
    label: 'Other - Please describe the condition in the Description box below',
  },
];
