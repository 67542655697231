import axios from 'axios';
import {
  CONNECTOR_DATABASE,
  CONNECTOR_LOGIN_ID,
  CONNECTOR_PASSWORD,
  BASE_DETAILS_API_URL,
} from '../const/envVariables';

const sqlRequest = axios.create({ baseURL: BASE_DETAILS_API_URL });

sqlRequest.interceptors.request.use(
  (config) => {
    config.params.ConnectorLoginId = CONNECTOR_LOGIN_ID;
    config.params.ConnectorPassword = CONNECTOR_PASSWORD;
    config.params.Database = CONNECTOR_DATABASE;
    return config;
  },

  (error) => Promise.reject(error),
);

export default sqlRequest;
