import { SupportTicketModel, TicketFormFields } from './../../types/entities';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BASE_DETAILS_API_URL,
  BPM_BASE_URL,
  BPM_CONNECTION_PASSWORD,
  BPM_CONNECTION_USERNAME,
  BPM_DEVELOPMENT_SERVER,
  CONNECTOR_DATABASE,
  CONNECTOR_LOGIN_ID,
  CONNECTOR_PASSWORD,
} from '../../shared/const/envVariables';
import { SqlService, supportTicketsApi } from '../../services';
import { JobsCriteria, SupportTicketCriteria } from '../../types/common';

import { store } from '../store';
import { BPM_ENDPOINS } from '../../shared/const/endpoints';

export const supportTicketsRtkApi = createApi({
  reducerPath: 'supportTicketsRtkApi',
  tagTypes: ['supportTickets'],
  refetchOnFocus: true,
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  endpoints: (builder) => ({
    getSupportTickets: builder.query<
      SupportTicketModel[],
      {
        customerNumber: string | undefined;
        ticketCriteria?: SupportTicketCriteria;
        jobsCriteria?: JobsCriteria;
        onlyWithJobnumber?: boolean;
      }
    >({
      query: ({
        customerNumber,
        ticketCriteria,
        jobsCriteria,
        onlyWithJobnumber,
      }) => ({
        url: `${BASE_DETAILS_API_URL}/api/DynamicSql/GetDynamicSQL`,
        params: {
          ConnectorLoginId: CONNECTOR_LOGIN_ID,
          ConnectorPassword: CONNECTOR_PASSWORD,
          Database: CONNECTOR_DATABASE,
          Sql: SqlService.getSupportTickets(
            customerNumber,
            ticketCriteria,
            jobsCriteria,
            onlyWithJobnumber,
          ),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ TicketNo }) => ({
                type: 'supportTickets' as const,
                TicketNo,
              })),
              { type: 'supportTickets', id: 'LIST' },
            ]
          : [{ type: 'supportTickets', id: 'LIST' }],
      transformResponse: (data: SupportTicketModel[]) => data.reverse(),
    }),
    getTicketById: builder.query<
      SupportTicketModel[] | undefined,
      { customerNumber: string | undefined; ticketNumber: string | undefined }
    >({
      query: ({ customerNumber, ticketNumber }) => ({
        url: `${BASE_DETAILS_API_URL}/api/DynamicSql/GetDynamicSQL`,
        params: {
          ConnectorLoginId: CONNECTOR_LOGIN_ID,
          ConnectorPassword: CONNECTOR_PASSWORD,
          Database: CONNECTOR_DATABASE,
          Sql: SqlService.getTicketById(customerNumber, ticketNumber),
        },
      }),
      providesTags: (TicketNo) => [{ type: 'supportTickets', TicketNo }],
    }),
    createTicket: builder.mutation<unknown, { values: TicketFormFields }>({
      async queryFn(
        _arg: { values: TicketFormFields },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        const nearestBranch = store.getState().user.userData?.nearestBranch;

        const data = JSON.stringify({
          fcConnection: {
            serverAddress: BPM_DEVELOPMENT_SERVER,
            userName: BPM_CONNECTION_USERNAME,
            password: BPM_CONNECTION_PASSWORD,
            useWindowsAuthentication: 'false',
            ceateFileForEventManager: 'false',
          },
          activityName: 'LO10_LogTicketJob',
          fcTactileCustomer: {
            sageSiteId: _arg.values.vmSiteName,
            sageCustomerId: _arg.values.customerId,
            nearestTactileBranch: nearestBranch,
          },
          fcTactileTicketInfo: {
            sageFaultId: _arg.values.reportedFaultName,
            equipmentPhysicalCondition: _arg.values.physicalCondition,
            priorityId: 'High',
            sageSerialNumber: _arg.values.equipmentNumber,
            sageJobType: _arg.values.jobType,
            ContactEmail: _arg.values.email,
            ContactPhone: _arg.values.phone,
            CustomerPOReference: _arg.values.customerReference,
            fcTactileEquipmentAccessories: _arg.values.accessories?.map(
              (item) => ({
                SageAccessoryId: item.item,
              }),
            ),
          },
        });

        const tokenData = await supportTicketsApi.getBPMToken();
        await fetchWithBQ({
          url: `${BPM_BASE_URL}${BPM_ENDPOINS.CREATE_TICKET}`,
          method: 'POST',
          headers: {
            Authorization: `${tokenData?.token_type} ${tokenData?.access_token}`,
            'Content-Type': 'application/json',
          },
          body: data,
        });
        return { data: null };
      },
      invalidatesTags: ['supportTickets'],
    }),
  }),
});

export const {
  useGetSupportTicketsQuery,
  useGetTicketByIdQuery,
  useCreateTicketMutation,
} = supportTicketsRtkApi;
