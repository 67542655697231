import { useRef, useCallback, memo } from 'react';
import { VariableSizeList as List, VariableSizeList } from 'react-window';
import { Empty } from 'antd';
import { Loading } from '../../../components/Loading/Loading';
import { SupportTicket } from '../SupportTicket/SupportTicket';
import S from './style.module.css';
import { SupportTicketModel } from '../../../types/entities';

interface Props {
  tickets: SupportTicketModel[];
  loading: boolean;
}

export const TicketList: React.FC<Props> = memo(({ tickets, loading }) => {
  const rowSizes = useRef<Record<string, number>>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<VariableSizeList>(null);

  const handleSize = useCallback((index: number, height: number) => {
    rowSizes.current = { ...rowSizes.current, [index]: height };
    listRef.current?.resetAfterIndex(index);
  }, []);

  const getItemSize = (index: number) => rowSizes.current[index] || 250;

  const Row = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => (
    <SupportTicket
      style={style}
      key={tickets[index]?.TicketNo || `ticket${index}`}
      data={tickets[index]}
      handleSize={handleSize}
      index={index}
    />
  );

  if (loading) {
    return <Loading size="large" />;
  }

  if (tickets.length < 1) {
    return <Empty style={{ width: '100%', height: '100%' }} />;
  }

  return (
    <div ref={containerRef} style={{ height: '100%', width: '100%' }}>
      <List
        ref={listRef}
        height={containerRef.current?.getBoundingClientRect().height || 700}
        itemCount={tickets.length}
        itemSize={getItemSize}
        width={'100%'}
        className={S.ticketList}
      >
        {Row}
      </List>
    </div>
  );
});
