import sqlRequest from '../../shared/utils/sqlRequest';

export const getJobTypes = async () => {
  try {
    const response = await sqlRequest({
      method: 'get',
      url: '/api/DynamicSql/GetDynamicSQL',
      params: {
        Sql: `select TXTYPE as jobId, TXDESC as jobName from VMTYPE`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
