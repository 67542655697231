import { Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Filters, MainButton } from '../../components';
import { TicketList } from '../../features/support-tickets/TicketList/TicketList';
import { Paths } from '../../shared/const/route-paths';
import { useAppSelector } from '../../hooks/hooks';
import { userSelectors } from '../../stores';
import S from './styles/support-tickets.module.css';
import { useGetSupportTicketsQuery } from '../../stores/support-tickets/api';
import { useDebounce } from '../../hooks/useDebounce';
import { SupportTicketCriteria } from '../../types/common';
import { SUPPORT_TICKETS_FILTERS } from '../../shared/const/common';

export const SupportTickets = () => {
  const navigate = useNavigate();
  const customerNumber = useAppSelector(userSelectors.getCustomerNumber);
  const [criteria, setCriteria] = useState({
    TicketStatus: '',
    Model: '',
    EquipmentSerial: '',
    SiteName: '',
    TicketResponsiblePerson: '',
    TicketCreatedByUser: '',
    TicketLoggedDate: '',
    EquipmentWarrantyStartDate: '',
    EquipmentWarrantyEndDate: '',
    ReportedFault: '',
  });

  const debouncedCriteria = useDebounce(criteria);

  const { data = [], isLoading } = useGetSupportTicketsQuery({
    customerNumber,
    ticketCriteria: debouncedCriteria,
  });

  return (
    <Container variant="vertical" className={S.container}>
      <Typography.Title style={{ marginBottom: 20 }} level={4}>
        {'Support tickets'}
      </Typography.Title>

      <MainButton
        className={S.addButton}
        onClick={() => navigate(Paths.APP.CREATE_TICKET)}
      >
        {'+ Add new ticket'}
      </MainButton>

      <Typography.Text strong type="secondary">
        {`${data?.length || 0} tickets found`}
      </Typography.Text>

      <Filters<SupportTicketCriteria>
        filters={SUPPORT_TICKETS_FILTERS}
        setfilterCritetia={setCriteria}
      />

      <TicketList tickets={data} loading={isLoading} />
    </Container>
  );
};
