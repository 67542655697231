import {
  CalendarOutlined,
  FileTextOutlined,
  ProfileOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Typography } from 'antd';
import { EquipmentModel } from '../../../../../types/entities';
import S from './style.module.css';
import dayjs from 'dayjs';

interface Props {
  data: EquipmentModel | undefined;
}

export const ModelInfoList: React.FC<Props> = ({ data }) => {
  const LIST_ITEM = [
    {
      label: 'Equipment Model ID: ',
      value: data?.ModelGroup,
      icon: <ProfileOutlined rev={undefined} />,
      id: '0',
    },
    {
      label: 'Manufacturer: ',
      value: data?.Manufacturer,
      icon: <ToolOutlined rev={undefined} />,
      id: '1',
    },
    {
      label: 'Installation Date: ',
      value: dayjs(data?.DateEquipmentInstalled).format('DD/MM/YYYY'),
      icon: <CalendarOutlined rev={undefined} />,
      id: '2',
    },
    {
      label: 'Equipment Last Job Number: ',
      value: data?.EquipmentLastJobNumber,
      icon: <FileTextOutlined rev={undefined} />,
      id: '3',
    },
    {
      label: 'Equipment Last Job Activity Date: ',
      value: dayjs(data?.DateOfLastRepair).format('DD/MM/YYYY'),
      icon: <CalendarOutlined rev={undefined} />,
      id: '4',
    },
  ];

  return (
    <div className={S.container}>
      {LIST_ITEM.map((item) => (
        <div
          key={item.id}
          style={{ display: 'flex', gap: 4, marginBottom: 12 }}
        >
          <Typography.Text
            className={S.text}
            type="secondary"
            style={{ display: 'flex', gap: 6 }}
          >
            {item.icon}
            {item.label}
          </Typography.Text>
          <Typography.Text className={S.text} strong>
            {item.value?.toString()}
          </Typography.Text>
        </div>
      ))}
    </div>
  );
};
