import { useMemo, memo } from 'react';
import { AdaptiveTable } from '../../AdaptiveTable/AdaptiveTable';
import { EquipmentsColumns } from '../../../shared/const/tableColumns';
import {
  EquipmentColumnsDataType,
  EquipmentModel,
} from '../../../types/entities';
import { onTableRowClick } from '../../../types/common';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../../shared/const/route-paths';
import { MAIN_COLOR } from '../../../shared/const/envVariables';
import { Typography } from 'antd';

interface Props {
  data: EquipmentModel[] | undefined;
  loading: boolean;
}

export const EquipmentTable: React.FC<Props> = memo(({ data, loading }) => {
  const navigate = useNavigate();

  const onRowClick = <T extends EquipmentColumnsDataType>(record: T) => {
    navigate(
      `${Paths.APP.SHOW_EQUIPMENT}/${record['Serial Number']?.props.children}`,
    );
  };

  const dataSource: {
    data: EquipmentColumnsDataType[];
    exclude: string[];
    cardTitles: string[] | null;
  } = useMemo(() => {
    if (!data || !data.length) return { data: [], exclude: [], cardTitles: [] };

    return {
      data: data.map((item: EquipmentModel, index: number) => {
        return {
          key: String(index),
          ['Serial Number']: (
            <Typography.Text strong style={{ color: MAIN_COLOR }}>
              {item.EquipmentSerialNo}
            </Typography.Text>
          ),
          ['Manufacturer']: item.Manufacturer,
          ['Model Description']: item.EquipmentModelDescription,
          ['Warranty Remaining Days']: item.WarrantyRemainingDays.toString(),
          ['Site Name']: item.SiteName,
        };
      }),
      exclude: ['key'],
      cardTitles: null,
    };
  }, [data]);

  return (
    <AdaptiveTable
      loading={loading}
      dataSource={dataSource.data}
      columns={EquipmentsColumns}
      pagination={false}
      excludeRows={dataSource.exclude}
      displayCardsWidth={1000}
      cardTitles={dataSource.cardTitles}
      // itemHeight={79}
      // virtualized
      // containerHeight="46vh"
      collapsed
      onRowClick={onRowClick as onTableRowClick}
      collapsedRows={['Serial Number', 'Manufacturer']}
    />
  );
});
