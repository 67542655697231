import {
  EquipmentCriteria,
  JobsCriteria,
  SupportTicketCriteria,
} from '../../types/common';

export const getEquipments = (
  customerNumber: string | undefined,
  debouncedCriteria?: EquipmentCriteria,
) =>
  `SELECT
  dbo.ARCUS.IDCUST AS CustomerID
    , dbo.ARCUS.NAMECUST AS CustomerName
    , dbo.VMSITE.TXSITE AS SiteID
    , dbo.VMSITE.TXSITENAME AS SiteName
    , dbo.VMCONP.TXNAME as SiteEquipmentContactPerson
    , dbo.VMEQUP.TXEQUP AS EquipmentID
    , dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTINSTALL) AS DateEquipmentInstalled
    , dbo.VMEQUP.TXUNFORMSN AS EquipmentSerialNo
    , dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTWARRST) AS WarrantyStartDate
    , dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTWARREN) AS WarrantyEndDate
    , DATEDIFF(DAY,(dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTWARRST)),(dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTWARREN))) as WarrantyRemainingDays
    , dbo.VMEQUP.TXLASTJOB as EquipmentLastJobNumber
    , dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTLASTMAIN) AS DateOfLastMaintenance
    , dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTLASTSERV) AS DateOfLastRepair
    , dbo.VMMODL.TXMODL AS ModelID
    , dbo.VMMODL.TXMANF AS Manufacturer
    , dbo.VMMODL.TXDESC AS EquipmentModelDescription
    , dbo.VMMODL.TXPARTNUM AS AssociatedStockItemCode
    , dbo.vw_ModelGroups.TXDESC AS ModelGroup
    , dbo.VMMTRA.TXMETER AS MeterID
    , dbo.ReturnDateFromACCPAC(dbo.VMMTRA.DTENTRY) AS DateLastMeterReading
    , dbo.VMMTRA.NMREADING AS CurrentMeterReading
    , dbo.VMMTRA.NMPREVREAD AS PreviousMeterReading
  FROM  dbo.VMCONP RIGHT OUTER JOIN
             dbo.VMEQUP ON dbo.VMCONP.NMCONT = dbo.VMEQUP.NMCONT LEFT OUTER JOIN
             dbo.VMMTRA RIGHT OUTER JOIN
             dbo.vw_NewestMeterReading ON dbo.VMMTRA.DTENTRY = dbo.vw_NewestMeterReading.DTENTRY AND dbo.VMMTRA.TXMETER = dbo.vw_NewestMeterReading.TXMETER AND dbo.VMMTRA.TXEQUP = dbo.vw_NewestMeterReading.TXEQUP ON dbo.VMEQUP.TXEQUP = dbo.vw_NewestMeterReading.TXEQUP LEFT OUTER JOIN
             dbo.vw_ModelGroups RIGHT OUTER JOIN
             dbo.VMMODL ON dbo.vw_ModelGroups.TXCLASS = dbo.VMMODL.TXMODLGRP ON dbo.VMEQUP.TXMODL = dbo.VMMODL.TXMODL RIGHT OUTER JOIN
             dbo.VMSITE ON dbo.VMEQUP.TXSITE = dbo.VMSITE.TXSITE LEFT OUTER JOIN
             dbo.ARCUS ON dbo.VMSITE.TXCUST = dbo.ARCUS.IDCUST
  WHERE dbo.ARCUS.IDCUST='${customerNumber}'
  ${
    debouncedCriteria?.EquipmentSerialNo
      ? `AND dbo.VMEQUP.TXUNFORMSN LIKE '%${debouncedCriteria.EquipmentSerialNo}%'`
      : ''
  }
  ${
    debouncedCriteria?.EquipmentModelDescription
      ? `AND dbo.VMMODL.TXDESC LIKE '%${debouncedCriteria.EquipmentModelDescription}%'`
      : ''
  }
  ${
    debouncedCriteria?.Manufacturer
      ? `AND dbo.VMMODL.TXMANF LIKE '%${debouncedCriteria.Manufacturer}%'`
      : ''
  }
  ${
    debouncedCriteria?.SiteName
      ? `AND dbo.VMSITE.TXSITENAME LIKE '%${debouncedCriteria.SiteName}%'`
      : ''
  }
  ${
    debouncedCriteria?.WarrantyRemainingDays
      ? `AND dbo.VMEQUP.TXUNFORMSN LIKE '%${debouncedCriteria.WarrantyRemainingDays}%'`
      : ''
  }
`.trim();

export const getSupportTickets = (
  customerNumber: string | undefined,
  ticketCriteria?: SupportTicketCriteria,
  jobsCriteria?: JobsCriteria,
  onlyWithJobnumber?: boolean,
) =>
  `select * from [dbo].[vw_WebPortalSupportTicketDetail] where CustomerID='${customerNumber}'
  ${onlyWithJobnumber ? 'and JobNumber is not NULL' : ''} 
  ${
    ticketCriteria?.EquipmentSerial
      ? `AND EquipmentSerial LIKE '%${ticketCriteria.EquipmentSerial}%'`
      : ''
  }
  ${
    ticketCriteria?.SiteName
      ? `AND SiteName LIKE '%${ticketCriteria.SiteName}%'`
      : ''
  }
  ${ticketCriteria?.Model ? `AND Model LIKE '%${ticketCriteria.Model}%'` : ''}
  ${
    ticketCriteria?.TicketCreatedByUser
      ? `AND TicketCreatedByUser LIKE '%${ticketCriteria.TicketCreatedByUser}%'`
      : ''
  }
  ${
    ticketCriteria?.TicketResponsiblePerson
      ? `AND TicketResponsiblePerson LIKE '%${ticketCriteria.TicketResponsiblePerson}%'`
      : ''
  }
  ${
    ticketCriteria?.TicketStatus
      ? `AND TicketStatus LIKE '%${ticketCriteria.TicketStatus}%'`
      : ''
  }
   
  ${
    jobsCriteria?.EquipmentSerial
      ? `AND EquipmentSerial LIKE '%${jobsCriteria.EquipmentSerial}%'`
      : ''
  }
  ${
    jobsCriteria?.JobNumber
      ? `AND JobNumber LIKE '%${jobsCriteria.JobNumber}%'`
      : ''
  }
  ${
    jobsCriteria?.JobStatus
      ? `AND JobStatus LIKE '%${jobsCriteria.JobStatus}%'`
      : ''
  }
  ${jobsCriteria?.JobType ? `AND JobType LIKE '%${jobsCriteria.JobType}%'` : ''}
  ${jobsCriteria?.Model ? `AND Model LIKE '%${jobsCriteria.Model}%'` : ''}
  ${
    jobsCriteria?.ReportedFault
      ? `AND ReportedFault LIKE '%${jobsCriteria.ReportedFault}%'`
      : ''
  }
  ${
    jobsCriteria?.SLARemainingTime
      ? `AND SLARemainingTime LIKE '%${jobsCriteria.SLARemainingTime}%'`
      : ''
  }
  ${
    jobsCriteria?.SiteName
      ? `AND SiteName LIKE '%${jobsCriteria.SiteName}%'`
      : ''
  }
  ${
    jobsCriteria?.TicketNo
      ? `AND TicketNo LIKE '%${jobsCriteria.TicketNo}%'`
      : ''
  }
  `.trim();

export const getTicketById = (
  customerNumber: string | undefined,
  ticketNumber: string | undefined,
) =>
  `select * from [dbo].[vw_WebPortalSupportTicketDetail] where CustomerID='${customerNumber}'
  AND TicketNo='${ticketNumber}'`;

export const getEquipmentBySerialId = (
  customerNumber: string | undefined,
  serialNumber: string | undefined,
) =>
  `SELECT
dbo.ARCUS.IDCUST AS CustomerID
  , dbo.ARCUS.NAMECUST AS CustomerName
  , dbo.VMSITE.TXSITE AS SiteID
  , dbo.VMSITE.TXSITENAME AS SiteName
  , dbo.VMCONP.TXNAME as SiteEquipmentContactPerson
  , dbo.VMEQUP.TXEQUP AS EquipmentID
  , dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTINSTALL) AS DateEquipmentInstalled
  , dbo.VMEQUP.TXUNFORMSN AS EquipmentSerialNo
  , dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTWARRST) AS WarrantyStartDate
  , dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTWARREN) AS WarrantyEndDate
  , DATEDIFF(DAY,(dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTWARRST)),(dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTWARREN))) as WarrantyRemainingDays
  , dbo.VMEQUP.TXLASTJOB as EquipmentLastJobNumber
  , dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTLASTMAIN) AS DateOfLastMaintenance
  , dbo.ReturnDateFromACCPAC(dbo.VMEQUP.DTLASTSERV) AS DateOfLastRepair
  , dbo.VMMODL.TXMODL AS ModelID
  , dbo.VMMODL.TXMANF AS Manufacturer
  , dbo.VMMODL.TXDESC AS EquipmentModelDescription
  , dbo.VMMODL.TXPARTNUM AS AssociatedStockItemCode
  , dbo.vw_ModelGroups.TXDESC AS ModelGroup
  , dbo.VMMTRA.TXMETER AS MeterID
  , dbo.ReturnDateFromACCPAC(dbo.VMMTRA.DTENTRY) AS DateLastMeterReading
  , dbo.VMMTRA.NMREADING AS CurrentMeterReading
  , dbo.VMMTRA.NMPREVREAD AS PreviousMeterReading
FROM  dbo.VMCONP RIGHT OUTER JOIN
           dbo.VMEQUP ON dbo.VMCONP.NMCONT = dbo.VMEQUP.NMCONT LEFT OUTER JOIN
           dbo.VMMTRA RIGHT OUTER JOIN
           dbo.vw_NewestMeterReading ON dbo.VMMTRA.DTENTRY = dbo.vw_NewestMeterReading.DTENTRY AND dbo.VMMTRA.TXMETER = dbo.vw_NewestMeterReading.TXMETER AND dbo.VMMTRA.TXEQUP = dbo.vw_NewestMeterReading.TXEQUP ON dbo.VMEQUP.TXEQUP = dbo.vw_NewestMeterReading.TXEQUP LEFT OUTER JOIN
           dbo.vw_ModelGroups RIGHT OUTER JOIN
           dbo.VMMODL ON dbo.vw_ModelGroups.TXCLASS = dbo.VMMODL.TXMODLGRP ON dbo.VMEQUP.TXMODL = dbo.VMMODL.TXMODL RIGHT OUTER JOIN
           dbo.VMSITE ON dbo.VMEQUP.TXSITE = dbo.VMSITE.TXSITE LEFT OUTER JOIN
           dbo.ARCUS ON dbo.VMSITE.TXCUST = dbo.ARCUS.IDCUST
WHERE dbo.ARCUS.IDCUST='${customerNumber}'
AND dbo.VMEQUP.TXUNFORMSN='${serialNumber}'`;
