import { type RadioChangeEvent } from 'antd';
import { useParams } from 'react-router-dom';
import { useState, useMemo, useCallback } from 'react';
import { Chat, TicketCard } from '../ticket-card-chat';
import { AdaptiveWrapper } from '../../AdaptiveWrapper/AdaptiveWrapper';
import S from './style.module.css';
import { SHOW_TICKET_RADIO_OPTIONS } from '../../../shared/const/common';
import { useGetTicketByIdQuery } from '../../../stores/support-tickets/api';
import { useAppSelector } from '../../../hooks/hooks';
import { userSelectors } from '../../../stores';

export const ShowTicketWrapper = () => {
  const { ticketNumber } = useParams();
  const customerNumber = useAppSelector(userSelectors.getCustomerNumber);
  const [checkedItem, setCheckedItem] = useState<string | null>('Ticket');

  const { data: ticketData, isLoading } = useGetTicketByIdQuery({
    customerNumber,
    ticketNumber,
  });

  const onChange = (e: RadioChangeEvent) => {
    setCheckedItem(e.target.value);
  };

  const setValue = useCallback(
    (value: string | null) => setCheckedItem(value),
    [],
  );

  const renderItems = useMemo(() => {
    if (checkedItem === 'Ticket') {
      return (
        <TicketCard
          loading={isLoading}
          tickets={ticketData}
          style={{
            maxWidth: 'none',
            width: '100%',
            border: 'none',
            padding: '16px 0',
          }}
        />
      );
    }

    if (checkedItem === 'Chat') {
      return <Chat roomId={ticketNumber as string} />;
    }

    return (
      <>
        <TicketCard loading={isLoading} tickets={ticketData} />
        <Chat roomId={ticketNumber as string} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItem, ticketData, ticketNumber]);

  return (
    <div className={S.container}>
      <AdaptiveWrapper
        displayBreakPoint={800}
        radioOptions={SHOW_TICKET_RADIO_OPTIONS}
        onRadioChange={onChange}
        setValue={setValue}
        defaultValue="Ticket"
        checkedItem={checkedItem}
      >
        <div style={{ display: 'flex' }}>{renderItems}</div>
      </AdaptiveWrapper>
    </div>
  );
};
