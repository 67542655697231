import { useLayoutEffect, useState } from 'react';
import { GoBackButton, RadioGroup } from '../../components';
import { type RadioChangeEvent } from 'antd';

interface Props {
  displayBreakPoint: number;
  radioOptions: { label: string; value: string; defaultChecked?: boolean }[];
  defaultValue?: string;
  onRadioChange?: (e: RadioChangeEvent) => void;
  setValue: (value: string | null) => void;
  checkedItem: string | null;
}

export const AdaptiveWrapper: React.FC<Props & React.PropsWithChildren> = ({
  displayBreakPoint,
  children,
  radioOptions,
  defaultValue,
  setValue,
  checkedItem,
  onRadioChange,
}) => {
  const [displayChildren, setDisplayChildren] = useState(false);

  useLayoutEffect(() => {
    function handleResize() {
      if (window.innerWidth <= displayBreakPoint && !displayChildren) {
        setDisplayChildren(true);
        setValue(radioOptions[0].value);
      } else {
        setDisplayChildren((prev) => (prev ? !prev : prev));
        setValue(null);
      }
    }
    handleResize();

    addEventListener('resize', handleResize);

    return () => removeEventListener('resize', handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GoBackButton />
      {displayChildren && (
        <RadioGroup
          defaultValue={defaultValue}
          options={radioOptions}
          onChange={onRadioChange}
          value={checkedItem}
        />
      )}
      {children}
    </>
  );
};
