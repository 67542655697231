import { Typography } from 'antd';
import { Outlet } from 'react-router-dom';
import { Container } from '../../components';
import { NavMenu } from '../../components/NavMenu/NavMenu';
import { UserAvatar } from '../../components/UserAvatar/UserAvatar';
import { PROFILE_NAV_LINKS } from '../../shared/const/common';
import { useAppSelector } from '../../hooks/hooks';
import { getUserData } from '../../stores/user/selectors';

export const Profile = () => {
  const userData = useAppSelector(getUserData);

  return (
    <Container variant="vertical">
      <Container
        style={{
          marginBottom: 20,
          display: 'flex',
          justifyContent: 'flex-start',
          gap: 8,
        }}
      >
        <UserAvatar size={52} />
        <Container
          variant="vertical"
          style={{
            alignItems: 'flex-start',
            width: 'auto',
          }}
        >
          <Typography.Title
            level={3}
          >{`${userData?.firstName} ${userData?.lastName}`}</Typography.Title>
          <Typography.Text>{userData?.customerNumber}</Typography.Text>
        </Container>
      </Container>

      <NavMenu navLinks={PROFILE_NAV_LINKS} />

      <Outlet />
    </Container>
  );
};
