import { Radio, type RadioGroupProps } from 'antd';
import S from './style.module.css';

interface Props {
  options: { label: string; value: string; defaultChecked?: boolean }[];
}

export const RadioGroup: React.FC<Props & RadioGroupProps> = ({
  options,
  ...props
}) => {
  return (
    <Radio.Group {...props} buttonStyle="solid">
      {options.map((option) => (
        <Radio.Button
          key={option.value}
          value={option.value}
          defaultChecked={option?.defaultChecked}
          className={S.radio}
        >
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
