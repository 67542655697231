import { Typography } from 'antd';
import { useAppSelector } from '../../../hooks/hooks';
import { useAsyncData } from '../../../hooks/useAsyncData';
import { equipmentApi } from '../../../services';
import { userSelectors } from '../../../stores';
import { RepairsHistoryTable } from '../RepairsHistoryTable/RepairsHistoryTable';

interface Props {
  serialNumber: string | undefined;
  style?: React.CSSProperties;
}

export const RepairsHistory: React.FC<Props> = ({ serialNumber, style }) => {
  const customerNumber = useAppSelector(userSelectors.getCustomerNumber);
  const [response, loading] = useAsyncData(
    equipmentApi.getTicketsBySerialNumber,
    serialNumber,
    customerNumber as string,
  );

  return (
    <div style={{ padding: '16px 0 24px 24px', width: '100%', ...style }}>
      <Typography.Title level={5}>{'Repairs History'}</Typography.Title>
      <RepairsHistoryTable
        data={response?.data ? response.data : []}
        loading={loading}
      />
    </div>
  );
};
