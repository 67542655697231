import { Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import {
  AccessoriesColumnsDataType,
  EquipmentColumnsDataType,
  JobsColumnsDataType,
  RepairsHistoryColumnsDataType,
  TicketCardJobsColumnsDataType,
} from '../../types/entities';
import { VmSiteDataType } from '../../types/profile';
import { sortColumn } from '../utils/sortColumn';
import { MAIN_COLOR } from './envVariables';

export const VmSiteColumns: ColumnsType<VmSiteDataType> = [
  {
    title: 'Vm Site',
    dataIndex: 'vmSiteName',
    key: 'vmSiteName',
    width: '16.6%',
    className: 'vm-site-name-column',
    sorter: sortColumn('vmSiteName'),
    render: (_, record) => (
      <Typography.Text strong style={{ color: MAIN_COLOR }}>
        {record.vmSiteName}
      </Typography.Text>
    ),
  },
  {
    title: 'ID',
    dataIndex: 'ID',
    key: 'ID',
    width: '16.6%',
  },
  {
    title: 'Contact Number',
    dataIndex: 'Contact Number',
    key: 'Contact Number',
    width: '16.6%',
  },
  {
    title: 'Contact Email',
    dataIndex: 'Contact Email',
    key: 'Contact Email',
    width: '16.6%',
  },
  {
    title: 'Address',
    dataIndex: 'Address',
    key: 'Address',
    width: '16.6%',
  },
  {
    title: 'Contact Person',
    dataIndex: 'Contact Person',
    key: 'Contact Person',
    width: '16.6%',
  },
];

export const AccessoriesColumns: ColumnsType<AccessoriesColumnsDataType> = [
  {
    title: '№',
    dataIndex: 'accessoryNumber',
    key: 'accessoryNumber',
    width: '33.33%',
    render: (_, record) => (
      <Typography.Text strong style={{ color: MAIN_COLOR }}>
        {record['№']}
      </Typography.Text>
    ),
  },
  {
    title: 'Item',
    dataIndex: 'Item',
    key: 'Item',
    width: '33.33%',
  },
  {
    title: 'Description',
    dataIndex: 'Description',
    key: 'Description',
    width: '33.33%',
  },
];

export const TicketCardJobsColumns: ColumnsType<TicketCardJobsColumnsDataType> =
  [
    {
      title: 'Job Number',
      dataIndex: 'Job Number',
      key: 'Job Number',
      width: '25%',
      render: (_, record) => (
        <Typography.Text strong style={{ color: MAIN_COLOR }}>
          {record['Job Number']}
        </Typography.Text>
      ),
    },
    {
      title: 'Job Name',
      dataIndex: 'Job Name',
      key: 'Job Name',
      width: '25%',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      width: '25%',
    },
    {
      title: 'Job created',
      dataIndex: 'Job created',
      key: 'Job created',
      width: '25%',
    },
  ];

export const JobsColumns: ColumnsType<JobsColumnsDataType> = [
  {
    title: 'Job Number',
    dataIndex: 'Job Number',
    key: 'Job Number',
    width: '9%',
    sorter: sortColumn('Job Number'),
    render: (_, record) => (
      <Typography.Text strong style={{ color: MAIN_COLOR }}>
        {record['Job Number']}
      </Typography.Text>
    ),
  },
  {
    title: 'Job Type',
    dataIndex: 'Job Type',
    key: 'Job Type',
    width: '9%',
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    width: '9%',
  },
  {
    title: 'Site',
    dataIndex: 'Site',
    key: 'Site',
    width: '9%',
  },
  {
    title: 'Related ticket number',
    dataIndex: 'Related ticket number',
    key: 'Related ticket number',
    width: '9%',
    sorter: sortColumn('Related ticket number'),
  },
  {
    title: 'Equipment Serial Number',
    dataIndex: 'Equipment Serial Number',
    key: 'Equipment Serial Number',
    width: '9%',
  },
  {
    title: 'Equipment Device',
    dataIndex: 'Equipment Device',
    key: 'Equipment Device',
    width: '9%',
  },
  {
    title: 'Fault',
    dataIndex: 'Fault',
    key: 'Fault',
    width: '9%',
  },
  {
    title: 'SLA Remaining Time',
    dataIndex: 'SLA Remaining Time',
    key: 'SLA Remaining Time',
    width: '9%',
  },
  {
    title: 'Job created',
    dataIndex: 'Job created',
    key: 'Job created',
    width: '9%',
    sorter: sortColumn('Job created'),
  },
  {
    title: 'Download Report',
    dataIndex: 'Download Report',
    key: 'Download Report',
    width: '9%',
  },
];

export const EquipmentsColumns: ColumnsType<EquipmentColumnsDataType> = [
  {
    title: 'Serial Number',
    dataIndex: 'Serial Number',
    key: 'Serial Number',
    width: '20%',
    sorter: sortColumn('Serial Number'),
    render: (_, record) => (
      <Typography.Text strong style={{ color: MAIN_COLOR }}>
        {record['Serial Number']}
      </Typography.Text>
    ),
  },
  {
    title: 'Manufacturer',
    dataIndex: 'Manufacturer',
    key: 'Manufacturer',
    width: '20%',
  },
  {
    title: 'Model Description',
    dataIndex: 'Model Description',
    key: 'Model Description',
    width: '20%',
    sorter: sortColumn('Model Description'),
  },
  {
    title: 'Warranty Remaining Days',
    dataIndex: 'Warranty Remaining Days',
    key: 'Warranty Remaining Days',
    width: '20%',
    sorter: sortColumn('Warranty Remaining Days'),
  },
  {
    title: 'Site Name',
    dataIndex: 'Site Name',
    key: 'Site Name',
    width: '20%',
  },
];

export const RepairsHistoryColumns: ColumnsType<RepairsHistoryColumnsDataType> =
  [
    {
      title: 'Job Number',
      dataIndex: 'Job Number',
      key: 'Job Number',
      width: '14.2%',
      render: (_, record) => (
        <Typography.Text strong style={{ color: MAIN_COLOR }}>
          {record['Job Number']}
        </Typography.Text>
      ),
    },
    {
      title: 'Job Type',
      dataIndex: 'Job Type',
      key: 'Job Type',
      width: '14.2%',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      width: '14.2%',
    },
    {
      title: 'Related ticket number',
      dataIndex: 'Related ticket number',
      key: 'Related ticket number',
      width: '14.2%',
    },
    {
      title: 'Fault',
      dataIndex: 'Fault',
      key: 'Fault',
      width: '14.2%',
    },
    {
      title: 'Job created',
      dataIndex: 'Job created',
      key: 'Job created',
      width: '14.2%',
    },
    {
      title: 'Download Report',
      dataIndex: 'Download Report',
      key: 'Download Report',
      width: '14.2%',
    },
  ];
