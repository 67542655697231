import React, { useMemo } from 'react';
import { AdaptiveTable } from '../../AdaptiveTable/AdaptiveTable';
import { EMPTY } from '../../../shared/const/common';
import { TicketCardJobsColumns } from '../../../shared/const/tableColumns';
import dayjs from 'dayjs';

import {
  SupportTicketModel,
  TicketCardJobsColumnsDataType,
} from '../../../types/entities';
import { Typography } from 'antd';
import { MAIN_COLOR } from '../../../shared/const/envVariables';

interface Props {
  ticketData: SupportTicketModel[] | undefined;
}

export const TicketJobsTable: React.FC<Props> = ({ ticketData }) => {
  const dataSource: {
    data: TicketCardJobsColumnsDataType[];
    exclude: string[];
    cardTitles: string[] | null;
  } = useMemo(() => {
    if (!ticketData) return { data: [], exclude: [], cardTitles: [] };

    return {
      data: ticketData
        .filter((item) => item.JobNumber)
        .map((item: SupportTicketModel, index: number) => {
          return {
            key: String(index),
            ['Job Number']: (
              <Typography.Text strong style={{ color: MAIN_COLOR }}>
                {item.JobNumber}
              </Typography.Text>
            ),
            ['Job Name']: item.JobType,
            ['Status']: (
              <span
                style={{
                  border: '1px solid #0389B6',
                  borderRadius: 20,
                  padding: '6px 12px',
                }}
              >
                {item?.JobStatus || EMPTY}
              </span>
            ),
            ['Job created']:
              dayjs(item?.JobCreatedDate).format('DD/MM/YYYY, HH:mm') || EMPTY,
          };
        }),
      exclude: ['key'],
      cardTitles: null,
    };
  }, [ticketData]);

  return (
    <AdaptiveTable
      dataSource={dataSource.data}
      columns={TicketCardJobsColumns}
      pagination={false}
      excludeRows={dataSource.exclude}
      displayCardsWidth={460}
      cardTitles={dataSource.cardTitles}
      collapsed
      collapsedRows={['Job Number', 'Job Name']}
    />
  );
};
