import { Typography } from 'antd';
import { JobsTable } from '../../features';
import { useState } from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { userSelectors } from '../../stores';
import { Filters } from '../../components/Filters/Filters';
import { JOBS_FILTERS } from '../../shared/const/common';
import { useGetSupportTicketsQuery } from '../../stores/support-tickets/api';
import { useDebounce } from '../../hooks/useDebounce';
import { JobsCriteria } from '../../types/common';

export const Jobs = () => {
  const customerNumber = useAppSelector(userSelectors.getCustomerNumber);
  const [filterCritetia, setfilterCritetia] = useState({
    JobNumber: '',
    JobType: '',
    JobStatus: '',
    SiteName: '',
    TicketNo: '',
    EquipmentSerial: '',
    Model: '',
    ReportedFault: '',
    SLARemainingTime: '',
    JobCreatedDate: '',
  });
  const debouncedCriteria = useDebounce(filterCritetia);

  const { data = [], isLoading } = useGetSupportTicketsQuery({
    customerNumber,
    jobsCriteria: debouncedCriteria,
    onlyWithJobnumber: true,
  });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Typography.Title style={{ marginBottom: 20 }} level={4}>
        {'Jobs'}
      </Typography.Title>
      <Typography.Paragraph strong type="secondary">
        {`${data?.length || 0} jobs found`}
      </Typography.Paragraph>
      <Filters<JobsCriteria>
        filters={JOBS_FILTERS}
        setfilterCritetia={setfilterCritetia}
      />
      <JobsTable data={data} loading={isLoading} />
    </div>
  );
};
