import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Typography } from 'antd';
import { AdaptiveTable } from '../../AdaptiveTable/AdaptiveTable';
import { DownloadReportButton } from '../../../components/DownloadReportButton/DownloadReportButton';
import { EMPTY } from '../../../shared/const/common';
import { MAIN_COLOR } from '../../../shared/const/envVariables';
import {
  RepairsHistoryColumnsDataType,
  SupportTicketModel,
} from '../../../types/entities';
import { RepairsHistoryColumns } from '../../../shared/const/tableColumns';

interface Props {
  data: SupportTicketModel[] | undefined;
  loading: boolean;
}

export const RepairsHistoryTable: React.FC<Props> = ({ data, loading }) => {
  const dataSource: {
    data: RepairsHistoryColumnsDataType[];
    exclude: string[];
    cardTitles: string[] | null;
  } = useMemo(() => {
    if (!data || !data.length) return { data: [], exclude: [], cardTitles: [] };

    return {
      data: data.map((item: SupportTicketModel, index: number) => {
        return {
          key: String(index),
          ['Job Number']: (
            <Typography.Text strong style={{ color: MAIN_COLOR }}>
              {item.JobNumber}
            </Typography.Text>
          ),
          ['Job Type']: item.JobType,
          ['Status']: (
            <span
              style={{
                border: '1px solid #0389B6',
                borderRadius: 20,
                padding: '6px 12px',
              }}
            >
              {item?.JobStatus || EMPTY}
            </span>
          ),
          ['Related ticket number']: item.TicketNo?.toString(),
          ['Fault']: item.ReportedFault,
          ['Job created']:
            dayjs(item?.JobCreatedDate).format('DD/MM/YYYY, HH:mm') || EMPTY,
          ['Download Report']: (
            <DownloadReportButton jobNumber={item.JobNumber?.trim()} />
          ),
        };
      }),
      exclude: ['key'],
      cardTitles: null,
    };
  }, [data]);

  return (
    <AdaptiveTable
      loading={loading}
      dataSource={dataSource.data}
      columns={RepairsHistoryColumns}
      pagination={false}
      excludeRows={dataSource.exclude}
      displayCardsWidth={609}
      cardTitles={dataSource.cardTitles}
      // itemHeight={566}
      // containerHeight="60vh"
      // virtualized
      collapsed
      collapsedRows={['Job Number', 'Job Type', 'Status']}
    />
  );
};
