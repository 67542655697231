import React, { useMemo } from 'react';
import { AdaptiveTable } from '../../AdaptiveTable/AdaptiveTable';
import { EMPTY } from '../../../shared/const/common';
import { AccessoriesColumns } from '../../../shared/const/tableColumns';

import {
  AccessoriesColumnsDataType,
  SupportTicketModel,
} from '../../../types/entities';
import { isString } from '../../../types/utils';

interface Props {
  ticketData: SupportTicketModel | undefined;
}

interface AccessoryModel {
  item: string;
  description: string;
}

export const AccessoriesTable: React.FC<Props> = ({ ticketData }) => {
  const isMultiple = isString(ticketData?.Accessory);

  const dataSource: {
    data: AccessoriesColumnsDataType[];
    exclude: string[];
    cardTitles: string[];
  } = useMemo(() => {
    if (!ticketData?.Accessory || isMultiple) {
      return { data: [], exclude: [], cardTitles: [] };
    }
    if (!isString(ticketData?.Accessory)) {
      return {
        data: ticketData?.Accessory.map(
          (item: AccessoryModel, index: number) => {
            return {
              key: String(index),
              ['№']: (index + 1).toString(),
              ['Item']: item.item,
              ['Description']: item?.description || EMPTY,
            };
          },
        ),
        exclude: ['key', '№'],
        cardTitles: [ticketData?.Accessory].map((_, index) =>
          (index + 1).toString(),
        ),
      };
    }

    return { data: [], exclude: [], cardTitles: [] };
  }, [isMultiple, ticketData?.Accessory]);

  return (
    <AdaptiveTable
      dataSource={dataSource.data}
      columns={AccessoriesColumns}
      pagination={false}
      excludeRows={dataSource.exclude}
      displayCardsWidth={400}
      cardTitles={dataSource.cardTitles}
    />
  );
};
