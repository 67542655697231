import { Typography } from 'antd';
import { Container } from '../../components';

export const RegistrationSuccess = () => {
  return (
    <Container variant="card">
      <Typography.Title level={3}>
        {'Thanks for the registration!'}
      </Typography.Title>

      <Typography.Paragraph strong>
        {
          'Details was sent to back-office to ensure validity of info and you would be able to log in after approval.'
        }
      </Typography.Paragraph>
    </Container>
  );
};
