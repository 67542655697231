export const sortColumn = <T extends Record<string, any>>(field: keyof T) => {
  return (a: T, b: T) => {
    if (!a[field] || !b[field]) {
      return 0;
    }

    const itemA = a[field].toString().toUpperCase();
    const itemB = b[field].toString().toUpperCase();
    if (itemA < itemB) {
      return -1;
    }
    if (itemA > itemB) {
      return 1;
    }

    return 0;
  };
};
