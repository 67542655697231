import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { Outlet } from 'react-router-dom';
import { Logo } from '../../components';
import { SideBarMenu } from '../../components/SideBarMenu/SideBarMenu';
import { LayoutHeader } from './components/LayoutHeader/LayoutHeader';
import S from './style.module.css';

export const SideBarLayout = () => {
  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider id={S.sider}>
          <Logo
            style={{ display: 'block', marginBottom: 24, paddingLeft: 16 }}
          />
          <SideBarMenu />
        </Sider>
        <Layout>
          <LayoutHeader />
          <Content className={S.content}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
