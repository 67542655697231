import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../../types/entities';

export interface UserState {
  isLoggedIn: boolean;
  userData: UserData | null;
  loading: boolean;
}

const initialState: UserState = {
  isLoggedIn: false,
  userData: null,
  loading: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
    logOut: (state) => {
      state.userData = initialState.userData;
      state.isLoggedIn = initialState.isLoggedIn;
      state.loading = initialState.loading;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    editUserData: (state, action: PayloadAction<UserData>) => {
      if (!state.userData) return;

      state.userData.firstName = action.payload.firstName;
      state.userData.lastName = action.payload.lastName;
      state.userData.phone = action.payload.phone;
      state.userData.email = action.payload.email;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
