import { RootState } from '../store';

export const getUserData = (state: RootState) => state.user.userData;

export const getLoading = (state: RootState) => state.user.loading;

export const getIsLoggedIn = (state: RootState) => state.user.isLoggedIn;

export const getCustomerNumber = (state: RootState) =>
  state.user.userData?.customerNumber;
