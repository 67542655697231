import { getEnv } from '../utils/getEnv';

export const MAIN_COLOR = getEnv('REACT_APP_MAIN_COLOR');
export const MAIN_COLOR_OPACITY_30 = getEnv('REACT_APP_MAIN_COLOR_OPACITY_30');

//MWARE API
export const BASE_URL = getEnv('REACT_APP_BASE_URL');

//SQL API
export const BASE_DETAILS_API_URL = getEnv('REACT_APP_BASE_DETAILS_API_URL');
export const CONNECTOR_LOGIN_ID = getEnv('REACT_APP_CONNECTOR_LOGIN_ID');
export const CONNECTOR_PASSWORD = getEnv('REACT_APP_CONNECTOR_PASSWORD');
export const CONNECTOR_DATABASE = getEnv('REACT_APP_CONNECTOR_DATABASE');

//BPM API
export const BPM_USERNAME = getEnv('REACT_APP_BPM_USERNAME');
export const BPM_PASSWORD = getEnv('REACT_APP_BPM_PASSWORD');
export const BPM_GRANT_TYPE = getEnv('REACT_APP_BPM_GRANT_TYPE');
export const BPM_CONNECTION_USERNAME = getEnv(
  'REACT_APP_BPM_CONNECTION_USERNAME',
);
export const BPM_CONNECTION_PASSWORD = getEnv(
  'REACT_APP_BPM_CONNECTION_PASSWORD',
);
export const BPM_DEVELOPMENT_SERVER = getEnv(
  'REACT_APP_BPM_DEVELOPMENT_SERVER',
);
export const BPM_BASE_URL = getEnv('REACT_APP_BPM_BASE_URL');
export const JOB_REPORTS_URL = getEnv('REACT_APP_JOB_REPORTS_URL');
export const REACT_APP_REACT_BRANCH_NAME = getEnv(
  'REACT_APP_REACT_BRANCH_NAME',
);
