import { Col, DatePicker, Form, Row, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { FormInput, MainButton, SelectLabeled } from '../../../components';
import { INPUT_TYPES } from '../../../shared/enums/common';
import { useEffect, useState, useMemo } from 'react';
import S from './style.module.css';
import {
  useAccessories,
  useAppDispatch,
  useAppSelector,
  useJobTypes,
  useReportedFaults,
  useVmSites,
} from '../../../hooks/hooks';
import * as userSelectors from '../../../stores/user/selectors';
import { supportTicketsApi } from '../../../services';
import {
  AccessoryModel,
  CreateTicketData,
  TicketFormFields,
} from '../../../types/entities';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { AccessoriesList } from '../accessories-list/AccessoriesList';
import { CONFIG } from '../../auth/RegisterForm/form-config';
import { physicalConditionData } from '../../../shared/const/common';
import { actions as alertActions } from '../../../stores/alert/slice';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';
import { Paths } from '../../../shared/const/route-paths';
import { useCreateTicketMutation } from '../../../stores/support-tickets/api';

export const TicketForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm<TicketFormFields>();
  const userData = useAppSelector(userSelectors.getUserData);
  const [ticketData, setTicketData] = useState<CreateTicketData | null>(null);
  const [loading, setLoading] = useState(false);
  const [createTicket] = useCreateTicketMutation();

  const jobTypes = useJobTypes();
  const vmSiteNames = useVmSites(userData?.customerNumber);
  const reportedFaults = useReportedFaults();
  const accessories = useAccessories();

  const debouncedRequest = useMemo(
    () =>
      debounce(async (value: string) => {
        const response = await supportTicketsApi.getTicketDataBySerialNubmer(
          value,
        );
        if (!response) {
          setTicketData(null);
          return;
        }
        setTicketData(response[0]);
      }, 1000),
    [],
  );

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        customerId: userData.customerNumber,
        customerName: userData.customerName,
        phone: userData.phone,
        email: userData.username,
      });
    }
  }, [form, userData]);

  useEffect(() => {
    if (!ticketData) {
      form.setFieldsValue({
        startDate: '',
        endDate: '',
        remainingDays: '',
        modelType: '',
        contactPerson: '',
        vmSiteName: '',
      });
    } else {
      form.setFieldsValue({
        startDate: dayjs(ticketData.WarrantyStartDate),
        endDate: dayjs(ticketData.WarrantyEndDate),
        remainingDays: String(ticketData.WarrantyRemainingDays),
        modelType: ticketData.EquipmentModelDescription,
        contactPerson: ticketData.SiteEquipmentContactPerson,
      });
    }
  }, [form, ticketData]);

  const handleSubmit = async (values: TicketFormFields) => {
    try {
      setLoading(true);

      await createTicket({ values });

      dispatch(
        alertActions.addAlert({
          message: 'Ticket has been created successfully',
          type: 'success',
        }),
      );
      navigate(Paths.APP.SUPPORT_TICKETS);
    } catch (error) {
      console.error(error);

      dispatch(
        alertActions.addAlert({
          message: 'Ticket creation has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error?.response?.status : '',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedRequest(event.target.value.trim());
  };

  return (
    <Form
      form={form}
      requiredMark={false}
      onFinish={handleSubmit}
      layout="vertical"
      name="ticket-form"
    >
      <div className={S.formContainer}>
        <div className={S.leftSide}>
          <Row justify="space-between" className={S.fieldsRow}>
            <Col span={11} className={S.column}>
              <FormInput
                name="customerId"
                placeholder="Customer ID"
                label="Customer ID"
                disabled
                rules={CONFIG.required}
              />
            </Col>
            <Col span={11} className={S.column}>
              <FormInput
                name="customerName"
                placeholder="Customer Name"
                label="Customer Name"
                disabled
                rules={CONFIG.required}
              />
            </Col>
          </Row>

          <Row justify="space-between" className={S.fieldsRow}>
            <Col span={11} className={S.column}>
              <FormInput
                name="equipmentNumber"
                placeholder="Enter serial number"
                label="Faulty Equipment Serial number"
                onChange={onChange}
                rules={CONFIG.required}
              />
            </Col>
            <Col span={11} className={S.column}>
              <FormInput
                name="modelType"
                placeholder="Device / Model Type"
                label="Device / Model Type"
                disabled
                rules={CONFIG.required}
              />
            </Col>
          </Row>

          <Row className={S.fieldsRow}>
            <Col span={8} className={S.column}>
              <FormItem
                label={
                  <Typography.Text type="secondary">
                    {'Warranty Start Date'}
                  </Typography.Text>
                }
                name="startDate"
                rules={CONFIG.required}
              >
                <DatePicker disabled format="DD/MM/YYYY" />
              </FormItem>
            </Col>
            <Col span={8} className={S.column}>
              <FormItem
                label={
                  <Typography.Text type="secondary">
                    {'Warranty End Date'}
                  </Typography.Text>
                }
                name="endDate"
                rules={CONFIG.required}
              >
                <DatePicker disabled format="DD/MM/YYYY" />
              </FormItem>
            </Col>
            <Col span={8} className={S.column}>
              <FormInput
                name="remainingDays"
                label="Warranty Remaining Days"
                disabled
                rules={CONFIG.required}
              />
            </Col>
          </Row>
          <Row justify="space-between" className={S.fieldsRow}>
            <Col span={11} className={S.column}>
              <SelectLabeled
                label="Physical condition of the faulty equipment"
                name="physicalCondition"
                placeholder="Choose condition"
                formItemProps={{ rules: CONFIG.required }}
                options={physicalConditionData}
                popupMatchSelectWidth={window.innerWidth <= 800 ? true : 800}
              />
            </Col>
            <Col span={11} className={S.column}>
              <SelectLabeled
                label="Job Type"
                options={jobTypes}
                name="jobType"
                formItemProps={{ rules: CONFIG.required }}
              />
            </Col>
          </Row>
          <SelectLabeled
            name="reportedFaultName"
            label="Reported Fault Name"
            formItemProps={{ rules: CONFIG.required }}
            options={reportedFaults}
          />

          <FormInput
            type={INPUT_TYPES.TEXT_AREA}
            name="description"
            autoSize={true}
            label="Description"
            inputStyles={{ minHeight: 90 }}
            rules={CONFIG.required}
          />

          <SelectLabeled
            label="VM Site"
            name="vmSiteName"
            options={vmSiteNames}
            formItemProps={{ rules: CONFIG.required }}
          />

          <Row justify="space-between" className={S.fieldsRow}>
            <Col span={11} className={S.column}>
              <FormInput
                label="Contact Email Address"
                name="email"
                placeholder="Enter contact email address"
                rules={CONFIG.username}
              />
            </Col>
            <Col span={11} className={S.column}>
              <FormInput
                label="Contact Phone Number"
                name="phone"
                placeholder="+27"
                rules={CONFIG.phone}
              />
            </Col>
          </Row>
        </div>
        <div className={S.rightSide}>
          <FormItem
            name="accessories"
            rules={[
              {
                validator(_, value: AccessoryModel[]) {
                  if (!value) return Promise.resolve();
                  if (value && value.every((acc) => acc.item)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('This field is required!'));
                },
              },
            ]}
          >
            <AccessoriesList
              setFieldValue={form.setFieldValue}
              accessoriesOptions={accessories}
            />
          </FormItem>

          <FormInput
            name="customerReference"
            label="Customer Reference"
            placeholder="Enter your reference"
          />
        </div>
      </div>
      <MainButton loading={loading}>{'Submit'}</MainButton>
    </Form>
  );
};
