import { Typography } from 'antd';
import { ForgotPasswordForm } from '../../features/auth/ForgotPasswordForm/ForgotPasswordForm';
import { useAppDispatch } from '../../hooks/hooks';
import { UserApi } from '../../services/user/api';
import { actions as alertActions } from '../../stores/alert/slice';

export const Password = () => {
  const dispatch = useAppDispatch();

  const onResetConfirm = async (values: {
    currentPassword: string;
    newPassword: string;
  }) => {
    await UserApi.setNewPassword({
      current_password: values.currentPassword,
      new_password: values.newPassword,
    });
    dispatch(
      alertActions.addAlert({
        message: 'Password has been reseted successfully',
        type: 'success',
      }),
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <Typography.Paragraph>
        {
          'Your password should contain at least one alphabet, one numeric and should be 10 to 20 characters long.'
        }
      </Typography.Paragraph>
      <ForgotPasswordForm formMode="newPassword" onSubmit={onResetConfirm} />
    </div>
  );
};
