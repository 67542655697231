import { CopyOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { type TextProps } from 'antd/es/typography/Text';
import { MAIN_COLOR } from '../../shared/const/envVariables';
import { useAppDispatch } from '../../hooks/hooks';
import { actions as alertActions } from '../../stores/alert/slice';
import S from './style.module.css';

interface Props {
  data: { copyValue: string | undefined | null };
  label?: string | React.ReactNode;
  labelProps?: TextProps;
}

export const CopyButton: React.FC<
  Props &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
> = ({ data, label, labelProps, ...divProps }) => {
  const dispatch = useAppDispatch();

  return (
    <div
      {...divProps}
      style={{
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        ...divProps.style,
      }}
    >
      {typeof label === 'string' ? (
        <Typography.Text strong className={S.text} {...labelProps}>
          {label}
        </Typography.Text>
      ) : (
        label
      )}
      <CopyOutlined
        style={{ color: MAIN_COLOR }}
        rev={undefined}
        size={16}
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(
              `${data.copyValue?.trim() || ''}`,
            );

            dispatch(
              alertActions.addAlert({
                type: 'success',
                message: 'Coppied to clipboard!',
              }),
            );
          } catch (error) {
            dispatch(
              alertActions.addAlert({
                type: 'error',
                message: 'Something went wrong!',
              }),
            );
          }
        }}
      />
    </div>
  );
};
