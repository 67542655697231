import { type AxiosResponse } from 'axios';
import { authRequest } from '../../shared/utils/authRequest';
import makeRequest from '../../shared/utils/makeRequest';
import { GetMessagesResponse } from '../../types/entities';

export const getAllChatMessages = async (
  ticketNumber: string,
  limit?: number,
  offset?: number,
): Promise<AxiosResponse<GetMessagesResponse> | undefined> => {
  try {
    return makeRequest({
      method: 'get',
      url: `/api/rooms/${ticketNumber}/messages/`,
      params: { limit, offset },
    });
  } catch (error) {
    console.error(error);
  }
};

export const sendChatMessage = async (
  ticketNumber: string,
  message: { content: string; room_id: string },
): Promise<
  | {
      type: 'chat.message';
      id: number;
      user_id: number;
      content: string;
    }
  | undefined
> => {
  try {
    const token = localStorage.getItem('_auth');
    return authRequest({
      method: 'post',
      url: `/ws/chat/${ticketNumber}/?token=${token}`,
      data: message,
    });
  } catch (error) {
    console.error(error);
  }
};
