import React, { useMemo, useState } from 'react';
import { Form } from 'antd';
import { FormInput } from '../../../components/FormInput/FormInput';
import { INPUT_TYPES } from '../../../shared/enums/common';
import { CONFIG } from '../RegisterForm/form-config';
import { getFieldsErrors } from '../../../shared/utils/getFieldsErrors';
import { AxiosError } from 'axios';
import { MainButton } from '../../../components';
import { useAppDispatch } from '../../../hooks/hooks';
import { actions as alertActions } from '../../../stores/alert/slice';

interface Props {
  formMode: 'resetConfirm' | 'reset' | 'newPassword';
  // eslint-disable-next-line no-unused-vars
  onSubmit: (values: any) => Promise<void>;
}

export const ForgotPasswordForm: React.FC<Props> = ({ formMode, onSubmit }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      await onSubmit(values);
    } catch (error) {
      const fieldsErrors = getFieldsErrors(
        error as AxiosError<Record<string, string[]>>,
      );

      if (!fieldsErrors) return;
      form.setFields(fieldsErrors);

      dispatch(
        alertActions.addAlert({
          message:
            formMode === 'newPassword'
              ? 'Password reseting has been failed'
              : 'Something went wrong',
          type: 'error',
          status: error instanceof AxiosError ? error.response?.status : '',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const setForm = useMemo(() => {
    switch (formMode) {
      case 'reset':
        return (
          <FormInput
            label="Email"
            name="username"
            placeholder="Enter your Username"
            rules={CONFIG.username}
          />
        );

      case 'resetConfirm':
        return (
          <>
            <FormInput
              label="Password"
              name="password"
              placeholder="Enter your Password"
              type={INPUT_TYPES.PASSWORD}
              rules={CONFIG.password}
            />

            <FormInput
              label="Confirm Password"
              name="confirm_password"
              placeholder="Confirm your Password"
              type={INPUT_TYPES.PASSWORD}
              rules={CONFIG.confirm_password}
            />
          </>
        );
      case 'newPassword':
        return (
          <>
            <FormInput
              label="Password"
              name="currentPassword"
              type={INPUT_TYPES.PASSWORD}
              rules={CONFIG.password}
              style={{ maxWidth: 430 }}
            />

            <FormInput
              label="New Password"
              name="newPassword"
              type={INPUT_TYPES.PASSWORD}
              rules={CONFIG.password}
              style={{ maxWidth: 430 }}
            />
          </>
        );
      default:
        break;
    }
  }, [formMode]);

  return (
    <Form
      form={form}
      name="register"
      onFinish={handleSubmit}
      layout="vertical"
      style={{ width: '100%' }}
      requiredMark={false}
    >
      {setForm}

      <MainButton
        style={{ maxWidth: formMode === 'newPassword' ? 220 : '' }}
        disabled={loading}
        loading={loading}
      >
        {formMode === 'newPassword' ? 'Change password' : 'Submit'}
      </MainButton>
    </Form>
  );
};
