import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { MAIN_COLOR } from '../../shared/const/envVariables';
import S from './style.module.css';

export const GoBackButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      className={S.backButton}
      htmlType="button"
      type="link"
      icon={<ArrowLeftOutlined rev={undefined} />}
      onClick={() => navigate(-1)}
    >
      <Typography.Text strong style={{ color: MAIN_COLOR, fontSize: 16 }}>
        {'Back'}
      </Typography.Text>
    </Button>
  );
};
