import { FileDoneOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { JOB_REPORTS_URL, MAIN_COLOR } from '../../shared/const/envVariables';

interface Props {
  jobNumber: string | null | undefined;
}

export const DownloadReportButton: React.FC<Props> = ({ jobNumber }) => {
  return (
    <Button
      type="link"
      style={{
        fontSize: 26,
        padding: 5,
        width: '100%',
        height: '100%',
      }}
      href={jobNumber ? `${JOB_REPORTS_URL}${jobNumber}.pdf` : undefined}
      target="_blank"
    >
      <FileDoneOutlined rev={undefined} color={MAIN_COLOR} />
    </Button>
  );
};
