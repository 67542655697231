import {
  BPM_GRANT_TYPE,
  BPM_PASSWORD,
  BPM_USERNAME,
} from '../../shared/const/envVariables';
import { ReportedFault } from './../../types/entities';
import { store } from '../../stores/store';
import sqlRequest from '../../shared/utils/sqlRequest';
import { actions as alertActions } from '../../stores/alert/slice';
import { BPM_ENDPOINS } from '../../shared/const/endpoints';
import { bpmRequest } from '../../shared/utils/bpmRequest';

export const getTicketDataBySerialNubmer = async (serialNumber: string) => {
  try {
    const response = await sqlRequest({
      method: 'get',
      url: '/api/DynamicSql/GetDynamicSQL',
      params: {
        Sql: `SELECT            dbo.ARCUS.IDCUST AS CustomerID,     dbo.ARCUS.NAMECUST AS CustomerName,     dbo.VMSITE.TXSITE AS SiteID,  dbo.VMSITE.TXSITENAME AS SiteName,
        dbo.VMCONP.TXNAME AS SiteEquipmentContactPerson,     dbo.VMMODL.TXMANF AS EquipmentManufacturer,     dbo.VMMODL.TXMODL AS EquipmentModelID,     
        dbo.VMMODL.TXDESC AS EquipmentModelDescription,     dbo.VMEQUP.TXUNFORMSN AS EquipmentSerialNumber,     dbo.VMEQUP.DTINSTALL AS EquipmentInstallationDate,     
        CASE         WHEN VMEQUP.DTWARRST = 0 THEN '1900/01/01'         ELSE CONVERT(DATE, CONVERT(VARCHAR(10), CONVERT(DATE, CONVERT(VARCHAR(8), VMEQUP.DTWARRST))), 111)     
        END AS WarrantyStartDate,     CASE         WHEN VMEQUP.DTWARREN = 0 THEN '1900/01/01'         ELSE CONVERT(DATE, CONVERT(VARCHAR(10), CONVERT(DATE, CONVERT(VARCHAR(8), 
        VMEQUP.DTWARREN))), 111)     END AS WarrantyEndDate,     DATEDIFF(         DAY,         (             CASE                 WHEN VMEQUP.DTWARRST = 0 THEN '1900/01/01'    
        ELSE CONVERT(DATE, CONVERT(VARCHAR(10), CONVERT(DATE, CONVERT(VARCHAR(8), VMEQUP.DTWARRST))), 111)             END         ),         
        (             CASE                 WHEN VMEQUP.DTWARREN = 0 THEN '1900/01/01'                 ELSE CONVERT(DATE, CONVERT(VARCHAR(10), CONVERT(DATE, CONVERT(VARCHAR(8), 
        VMEQUP.DTWARREN))), 111)             END         )     ) AS WarrantyRemainingDays,     dbo.VMEQUP.TXLASTJOB AS EquipmentLastJobNumber,     
        dbo.VMEQUP.DTLASTSERV AS EquipmentLastJobActivityDate,     dbo.VMEQUP.TXINV AS EquipmentOriginalSalesInvoiceNumber FROM dbo.VMCONP RIGHT OUTER JOIN dbo.VMEQUP ON 
        dbo.VMCONP.NMCONT = dbo.VMEQUP.NMCONT LEFT OUTER JOIN dbo.VMMODL ON dbo.VMEQUP.TXMODL = dbo.VMMODL.TXMODL RIGHT OUTER JOIN dbo.VMSITE ON dbo.VMEQUP.TXSITE = 
        dbo.VMSITE.TXSITE RIGHT OUTER JOIN dbo.ARCUS ON dbo.VMSITE.TXCUST = dbo.ARCUS.IDCUST WHERE dbo.VMEQUP.TXUNFORMSN = '${serialNumber}'`,
      },
    });
    if (response.data.length === 0) {
      store.dispatch(
        alertActions.addAlert({
          message: "The device hasn't been found",
          type: 'error',
        }),
      );
      return null;
    }

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getReportedFaults = async (): Promise<
  ReportedFault[] | undefined
> => {
  try {
    const response = await sqlRequest({
      method: 'get',
      url: '/api/DynamicSql/GetDynamicSQL',
      params: {
        Sql: 'select  TXFAULT as FaultID  , TXDESC as FaultDescription  , TXCOMMENT as FaultComment  from VMFALT',
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAccessories = async () => {
  try {
    const response = await sqlRequest({
      method: 'get',
      url: '/api/DynamicSql/GetDynamicSQL',
      params: {
        Sql: 'select  TXCLASS as AccessoryID  , TXDESC as AccessoryDescription  from VMCLAS',
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getBPMToken = async (): Promise<
  | {
      access_token: string;
      expires_in: number;
      token_type: string;
    }
  | undefined
> => {
  try {
    const data = new URLSearchParams({
      username: BPM_USERNAME as string,
      password: BPM_PASSWORD as string,
      grant_type: BPM_GRANT_TYPE as string,
    }).toString();

    const response = await bpmRequest({
      url: BPM_ENDPOINS.GET_BPM_TOKEN,
      method: 'post',
      data: data,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
