import { Col, Form, type FormProps, Row, Typography } from 'antd';
import { AxiosError } from 'axios';
import { FormInput } from '../../../components/FormInput/FormInput';
import { INPUT_TYPES } from '../../../shared/enums/common';
import { getFieldsErrors } from '../../../shared/utils/getFieldsErrors';
import { CONFIG } from './form-config';
import { useEffect, useMemo, useState } from 'react';
import S from './style.module.css';
import { MainButton, SelectLabeled } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import * as authSelectors from '../../../stores/user/selectors';
import { actions as alertActions } from '../../../stores/alert/slice';
import { NEAREST_BRANCH_OPTIONS } from '../../../shared/const/common';
import { REACT_APP_REACT_BRANCH_NAME } from '../../../shared/const/envVariables';

interface Props {
  formMode: 'create' | 'edit';
  onSubmit: (values: any) => void;
}

export const RegisterForm: React.FC<Props & FormProps> = ({
  formMode,
  onSubmit,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const isEditForm = formMode === 'edit';
  const userData = useAppSelector(authSelectors.getUserData);

  const initialValues = useMemo(
    () => ({
      create: {
        agreement: true,
        phone: '+',
      },
      edit: {
        phone: userData?.phone,
        username: userData?.username,
        first_name: userData?.firstName,
        last_name: userData?.lastName,
        email: userData?.email,
        customer_number: userData?.customerNumber,
        nearest_branch: userData?.nearestBranch,
        customer_name: userData?.customerName,
      },
    }),
    [userData],
  );
  useEffect(() => {
    form.setFieldsValue(isEditForm ? initialValues.edit : initialValues.create);
  }, [form, initialValues, isEditForm]);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);

      await onSubmit(values);

      setLoading(false);

      if (!isEditForm) return;

      dispatch(
        alertActions.addAlert({
          message: 'Profile has been edited succesfully',
          type: 'success',
        }),
      );
    } catch (error) {
      setLoading(false);
      const fieldsErrors = getFieldsErrors(
        error as AxiosError<Record<string, string[]>>,
      );
      if (!fieldsErrors) return;
      form.setFields(fieldsErrors);

      dispatch(
        alertActions.addAlert({
          message: isEditForm
            ? 'Profile editing has been failed'
            : 'Registration has been failed',
          type: 'error',
          status: error instanceof AxiosError ? error?.response?.status : '',
        }),
      );
    }
  };

  return (
    <Form form={form} requiredMark={false} onFinish={handleSubmit} {...props}>
      <div className={isEditForm ? S.editContainer : S.createContainer}>
        <FormInput
          label="Username (Email)"
          name="username"
          placeholder="Enter your Username"
          rules={CONFIG.username}
          onBlur={(event) => {
            if (form.isFieldTouched('email')) return;
            form.setFieldValue('email', event.target.value);
          }}
          disabled={isEditForm}
          className={isEditForm ? S.input : ''}
        />

        {!isEditForm && (
          <>
            <FormInput
              label="Password"
              name="password"
              placeholder="Enter your Password"
              type={INPUT_TYPES.PASSWORD}
              rules={CONFIG.password}
            />

            <FormInput
              label="Confirm Password"
              name="confirm_password"
              placeholder="Confirm your Password"
              type={INPUT_TYPES.PASSWORD}
              rules={CONFIG.confirm_password}
            />
          </>
        )}

        <FormInput
          label="First name"
          name="first_name"
          placeholder="Enter your First name"
          rules={CONFIG.first_name}
          className={isEditForm ? S.input : ''}
        />

        <FormInput
          label="Last name"
          name="last_name"
          placeholder="Enter your Last name"
          rules={CONFIG.last_name}
          className={isEditForm ? S.input : ''}
        />

        <Row justify="space-between">
          <Col span={11}>
            <FormInput
              label="Contact Phone Number"
              name="phone"
              placeholder="+27"
              rules={CONFIG.phone}
              className={isEditForm ? S.input : ''}
            />
          </Col>
          <Col span={11}>
            <FormInput
              label="Contact Email Address"
              name="email"
              placeholder="Enter contact email address"
              rules={CONFIG.email}
              className={isEditForm ? S.input : ''}
            />
          </Col>
        </Row>

        <FormInput
          label={'Customer Name'}
          name="customer_name"
          placeholder="Enter Customer Name"
          className={isEditForm ? S.input : ''}
          disabled={isEditForm}
          rules={CONFIG.required}
        />

        <FormInput
          label={isEditForm ? 'Customer ID' : 'Customer ID (optional)'}
          name="customer_number"
          placeholder="Enter Customer ID"
          className={isEditForm ? S.input : ''}
          disabled={isEditForm}
        />

        <SelectLabeled
          name="nearest_branch"
          options={NEAREST_BRANCH_OPTIONS}
          className={isEditForm ? S.input : ''}
          disabled={isEditForm}
          formItemProps={{ rules: CONFIG.required }}
          placeholder={'Choose Tactile Branch'}
          label={
            <Typography.Text type="secondary">
              {REACT_APP_REACT_BRANCH_NAME}
            </Typography.Text>
          }
        />

        {!isEditForm && (
          <FormInput
            label="Agree to the Terms and Conditions"
            name="agreement"
            type={INPUT_TYPES.CHECKBOX}
            rules={CONFIG.agreement}
            defaultChecked
            valuePropName="checked"
          />
        )}
      </div>

      <MainButton
        loading={loading}
        disabled={loading}
        className={isEditForm ? S.editButton : ''}
      >
        {isEditForm ? 'Submit' : 'Sign Up'}
      </MainButton>
    </Form>
  );
};
