import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  MAIN_COLOR,
  MAIN_COLOR_OPACITY_30,
} from '../../../shared/const/envVariables';
import { Paths } from '../../../shared/const/route-paths';
import { type SupportTicketModel } from '../../../types/entities';
import { DefaultAvatar } from '../../../components/DefaultAvatar/DefaultAvatar';
import S from './style.module.css';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';
import { EMPTY } from '../../../shared/const/common';

interface Props {
  data: SupportTicketModel;
  style: React.CSSProperties;
  handleSize: (index: number, height: number) => void;
  index: number;
}

export const SupportTicket: React.FC<Props> = ({
  data,
  style,
  handleSize,
  index,
}) => {
  const navigate = useNavigate();
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (itemRef.current) {
      handleSize(index, itemRef.current.getBoundingClientRect().height);
    }
  }, [handleSize, index, itemRef]);

  return (
    <div
      style={{
        ...style,
        height: 'auto',
        top: index === 0 ? style.top : Number(style.top) + index * 20,
      }}
      className={S.card}
      onClick={() => navigate(`${Paths.APP.SHOW_TICKET}/${data?.TicketNo}`)}
      ref={itemRef}
    >
      <div className={S.ticketInfoContainer}>
        <div className={S.ticketInfo}>
          <div className={S.ticketNumber}>
            <Typography.Text>{data?.TicketNo}</Typography.Text>
            <Typography.Text className={S.createdStatus}>
              {data?.TicketStatus || EMPTY}
            </Typography.Text>
          </div>

          <Typography.Title level={5} id={S.title}>
            {data.Model}
          </Typography.Title>

          <div className={S.numberVmSiteNameContainer}>
            <Typography.Paragraph style={{ marginBottom: 8 }}>
              {`Serial number: ${data?.EquipmentSerial}`}
            </Typography.Paragraph>

            <div className={S.vmSiteName}>
              <Typography.Text type="secondary">{'VM Site: '}</Typography.Text>
              <Typography.Text>{data.SiteName}</Typography.Text>
            </div>
          </div>

          <Typography.Text
            type="secondary"
            style={{ marginBottom: 8, display: 'block' }}
          >
            {data?.EquipmentReportedProblem}
          </Typography.Text>

          <div className={S.participants}>
            <div className={S.createdBy}>
              <Typography.Text type="secondary" className={S.text}>
                {'Created by:'}
              </Typography.Text>
              <div className={S.AvatarNameContainer}>
                <DefaultAvatar size={24} />
                <Typography.Text className={S.text}>
                  {data.TicketCreatedByUser || EMPTY}
                </Typography.Text>
              </div>
            </div>
            <div className={S.reponsiblePerson}>
              <Typography.Text type="secondary" className={S.text}>
                {'Responsible Person:'}
              </Typography.Text>
              <div className={S.AvatarNameContainer}>
                <DefaultAvatar size={24} />
                <Typography.Text className={S.text}>
                  {data.TicketResponsiblePerson || EMPTY}
                </Typography.Text>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={S.relatedJobContainer}>
        {data.JobNumber && (
          <Typography.Text
            className={S.relatedJob}
            style={{ backgroundColor: MAIN_COLOR_OPACITY_30 || MAIN_COLOR }}
          >
            {`Related job: ${data.JobNumber}`}
          </Typography.Text>
        )}
        {data.EquipmentWarrantyRemainingDays < 3 && (
          <Typography.Text strong type="danger">
            {'Close to deadline!'}
          </Typography.Text>
        )}
      </div>

      <div className={S.dates}>
        <div className={S.loggedDate}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: 12, marginBottom: 3 }}
          >
            {'Support Ticket logged'}
          </Typography.Text>
          <Typography.Text className={S.text}>
            {dayjs(data.EquipmentWarrantyStartDate)
              .format('DD/MM/YYYY, HH:mm')
              .toString()}
          </Typography.Text>
        </div>
        <div className={S.expiryDate}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: 12, marginBottom: 3 }}
          >
            {'Job Card Expiry Date'}
          </Typography.Text>
          <Typography.Text className={S.text}>
            {dayjs(data.EquipmentWarrantyEndDate)
              .format('DD/MM/YYYY, HH:mm')
              .toString()}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
