import { Typography } from 'antd';
import { EQUIPMENT_FILTERS } from '../../shared/const/common';
import { EquipmentTable } from '../../features';
import { useState } from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { Filters } from '../../components/Filters/Filters';
import { useDebounce } from '../../hooks/useDebounce';
import { userSelectors } from '../../stores';
import { EquipmentCriteria } from '../../types/common';
import { useGetEquipmentsQuery } from '../../stores/equipment/api';

export const Equipment = () => {
  const customerNumber = useAppSelector(userSelectors.getCustomerNumber);
  const [filterCriteria, setFilterCriteria] = useState({
    EquipmentModelDescription: '',
    Manufacturer: '',
    EquipmentSerialNo: '',
    WarrantyRemainingDays: '',
    SiteName: '',
  });
  const debouncedCriteria = useDebounce(filterCriteria);

  const { data, isLoading } = useGetEquipmentsQuery({
    customerNumber: customerNumber,
    criteria: debouncedCriteria,
  });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Typography.Title style={{ marginBottom: 20 }} level={4}>
        {'Equipment'}
      </Typography.Title>
      <Typography.Paragraph strong type="secondary">
        {`${data?.length || 0} equipment items found`}
      </Typography.Paragraph>

      <Filters<EquipmentCriteria>
        filters={EQUIPMENT_FILTERS}
        setfilterCritetia={setFilterCriteria}
      />
      <EquipmentTable data={data} loading={isLoading} />
    </div>
  );
};
