import {
  PrinterOutlined,
  ScheduleOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Typography } from 'antd';
import { useMemo } from 'react';
import { Container } from '../../../../components';
import { useAppSelector } from '../../../../hooks/hooks';
import { userSelectors } from '../../../../stores';
import { useGetEquipmentsQuery } from '../../../../stores/equipment/api';
import { useGetSupportTicketsQuery } from '../../../../stores/support-tickets/api';
import S from './style.module.css';

export const CountList = () => {
  const customerNumber = useAppSelector(userSelectors.getCustomerNumber);
  const { data: tickets } = useGetSupportTicketsQuery({ customerNumber });
  const { data: equipment } = useGetEquipmentsQuery({ customerNumber });

  const items = useMemo(
    () => [
      {
        label: 'Total Equipment Count',
        icon: <PrinterOutlined rev={undefined} />,
        value: equipment?.length || 0,
      },
      {
        label: 'Total Job Count',
        icon: <ScheduleOutlined rev={undefined} />,
        value: tickets?.filter((item) => item.JobNumber).length || 0,
      },
      {
        label: 'Total Support Ticket Count',
        icon: <ToolOutlined rev={undefined} />,
        value: tickets?.length || 0,
      },
    ],
    [equipment?.length, tickets?.length],
  );

  return (
    <Container
      variant="space-between"
      style={{
        color: `${process.env.REACT_APP_MAIN_COLOR || '#75BC46'}`,
      }}
      className={S.container}
    >
      {items.map((item) => {
        return (
          <div key={`countList/${item.label}`} className={S.item}>
            <div className={S.itemContainer}>
              {item.icon}
              <Typography.Paragraph
                strong
                style={{
                  color: `${process.env.REACT_APP_MAIN_COLOR || '#75BC46'}`,
                  margin: 0,
                }}
                className={S.paragraph}
              >
                {item.label}
              </Typography.Paragraph>
            </div>
            <Typography.Paragraph
              style={{ margin: 0 }}
              strong
              className={S.paragraph}
            >
              {item.value}
            </Typography.Paragraph>
          </div>
        );
      })}
    </Container>
  );
};
