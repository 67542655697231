import { Empty, Typography } from 'antd';
import React from 'react';
import { CopyButton, Toggler } from '../../../components';
import { DefaultAvatar } from '../../../components/DefaultAvatar/DefaultAvatar';
import { EMPTY } from '../../../shared/const/common';
import { EquipmentModel } from '../../../types/entities';
import dayjs from 'dayjs';
import S from './style.module.css';
import { ModelInfoList } from './components/ModelInfoList/ModelInfoList';
import { Loading } from '../../../components/Loading/Loading';

interface Props {
  data: EquipmentModel | undefined;
  style?: React.CSSProperties;
  loading?: boolean;
}

export const EquipmentCard: React.FC<Props> = ({ data, style, loading }) => {
  if (loading) {
    return <Loading size="default" />;
  }

  if (!data) {
    return (
      <Empty
        style={
          style ? style : { width: '100%', borderRight: '1px solid #D8D8D8' }
        }
      />
    );
  }

  return (
    <div className={S.cardInfo} style={style}>
      <Typography.Title level={5}>
        {data?.EquipmentModelDescription || EMPTY}
      </Typography.Title>
      <CopyButton
        data={{ copyValue: data?.EquipmentSerialNo || EMPTY }}
        label={data?.EquipmentSerialNo || EMPTY}
        labelProps={{ strong: true }}
        style={{ marginBottom: 16 }}
      />

      <ModelInfoList data={data} />

      <div className={S.personsContainer}>
        <div
          style={{
            display: 'flex',
            gap: 8,
            flexWrap: 'wrap',
          }}
        >
          <Typography.Title level={5}>{data?.SiteName}</Typography.Title>
          <Typography.Text>{data?.SiteID}</Typography.Text>
        </div>
        <Typography.Text
          className={S.text}
          style={{ marginBottom: 5 }}
          type="secondary"
        >
          {'Site Equipment Contact Person'}
        </Typography.Text>
        <div
          style={{
            display: 'flex',
            gap: 8,
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <DefaultAvatar size={24} />
          <Typography.Text className={S.text}>
            {data?.SiteEquipmentContactPerson}
          </Typography.Text>
        </div>
      </div>
      <Toggler label={'Waranty'}>
        <div className={S.warantyContainer}>
          <div className={S.warantyItem}>
            <Typography.Text
              className={S.text}
              type="secondary"
              style={{ marginBottom: 3 }}
            >
              {'Start Date'}
            </Typography.Text>
            <Typography.Text className={S.text}>
              {dayjs(data?.WarrantyStartDate).format('DD/MM/YYYY').toString()}
            </Typography.Text>
          </div>
          <div className={S.warantyItem}>
            <Typography.Text
              className={S.text}
              type="secondary"
              style={{ marginBottom: 3 }}
            >
              {'End Date'}
            </Typography.Text>
            <Typography.Text className={S.text}>
              {dayjs(data?.WarrantyEndDate).format('DD/MM/YYYY').toString()}
            </Typography.Text>
          </div>
          <div className={S.warantyItem}>
            <Typography.Text
              className={S.text}
              type="secondary"
              style={{ marginBottom: 3 }}
            >
              {'Remaining Days'}
            </Typography.Text>
            <Typography.Text className={S.text}>
              {data?.WarrantyRemainingDays}
            </Typography.Text>
          </div>
        </div>
      </Toggler>
    </div>
  );
};
