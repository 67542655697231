import { EditRequest, UserData } from './../../types/entities';
import { ENDPOINTS } from '../../shared/const/endpoints';
import makeRequest from '../../shared/utils/makeRequest';
import type { AxiosResponse } from 'axios';
import { authRequest } from '../../shared/utils/authRequest';

export class UserApi {
  static async getUserData(): Promise<AxiosResponse<UserData>> {
    return makeRequest({
      method: 'get',
      url: ENDPOINTS.GET_USER_DATA,
    });
  }
  static async resetPassword(data: { username: string }) {
    return authRequest({
      method: 'post',
      url: ENDPOINTS.RESET_PASSWORD,
      data,
    });
  }
  static async resetPasswordConfirm(data: {
    token: string;
    uid: string;
    new_password: string;
  }) {
    return makeRequest({
      method: 'post',
      url: ENDPOINTS.RESET_PASSWORD_CONFIRM,
      data,
    });
  }
  static async setNewPassword(data: {
    current_password: string;
    new_password: string;
  }) {
    return makeRequest({
      method: 'post',
      url: ENDPOINTS.SET_NEW_PASSWORD,
      data,
    });
  }
  static async uploadFile(data: any): Promise<
    AxiosResponse<
      {
        contentType: string;
        id: number;
        name: string;
        size: number;
        url: string;
        user: number;
      }[]
    >
  > {
    return makeRequest({
      method: 'post',
      url: ENDPOINTS.UPLOAD_FILE,
      data,
    });
  }
  static async editUserData({
    id,
    data,
  }: {
    id?: number;
    data: Partial<EditRequest>;
  }): Promise<AxiosResponse<UserData>> {
    return makeRequest({
      method: 'patch',
      url: `${ENDPOINTS.EDIT_USER}${id}/`,
      data,
    });
  }
}
