import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import type { RootState, AppDispatch } from '../stores/store';
import { useEffect } from 'react';
import { UserApi } from '../services/user/api';
import { userActions } from '../stores/user/slice';
import { useAsyncData } from './useAsyncData';
import { jobApi, supportTicketsApi, vmSitesApi } from '../services';
import { VmSiteSqlData } from '../types/profile';
import { userSelectors } from '../stores';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../shared/const/route-paths';
import { AuthService } from '../services/auth/service';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export async function useUserData() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(userSelectors.getIsLoggedIn);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(userActions.setLoading(true));

      UserApi.getUserData()
        .then((res) => res?.data)
        .then((res) => dispatch(userActions.setUserData(res)))
        .finally(() => dispatch(userActions.setLoading(false)));
    }
  }, [dispatch, isAuthenticated]);
}

export function useLogout() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (!event.key && !event.newValue) {
        dispatch(userActions.setIsLoggedIn(false));
        AuthService.logOut();
      }
    };

    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, [dispatch]);
}

export function useAuthenticateUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(userSelectors.getIsLoggedIn);
  const token = localStorage.getItem('_auth');
  const refreshToken = localStorage.getItem('_auth_refresh');

  useEffect(() => {
    if (!isAuthenticated && token) {
      dispatch(userActions.setLoading(true));
      AuthService.checkTokenValidity(token)
        .then((isTokenValid) => {
          if (isTokenValid) {
            dispatch(userActions.setIsLoggedIn(true));
            navigate(Paths.APP.SUPPORT_TICKETS);
          }
        })
        .catch(() => {
          if (refreshToken) {
            AuthService.refreshToken({
              refresh: refreshToken,
            })
              .then((res) => {
                localStorage.setItem('_auth', res.data.access);
                localStorage.setItem('_auth_refresh', res.data.refresh);

                dispatch(userActions.setIsLoggedIn(true));
                navigate(Paths.APP.SUPPORT_TICKETS);
              })
              .catch(() => {
                if (isAuthenticated) {
                  dispatch(userActions.setIsLoggedIn(false));
                  navigate(Paths.AUTH, { replace: true });
                }
              });
          } else {
            dispatch(userActions.setIsLoggedIn(false));
            navigate(Paths.AUTH, { replace: true });
          }
        })
        .finally(() => dispatch(userActions.setLoading(false)));
    }
  }, [dispatch, isAuthenticated, navigate, refreshToken, token]);
}

export function useJobTypes() {
  const [jobTypes] = useAsyncData<
    () => Promise<{ jobId: string; jobName: string }[]>
  >(jobApi.getJobTypes);

  return jobTypes?.map((job) => ({
    value: job.jobId.trim(),
    label: job.jobName.trim(),
  }));
}

export function useReportedFaults() {
  const [reportedFaults] = useAsyncData(supportTicketsApi.getReportedFaults);
  return reportedFaults?.map((item) => ({
    value: item.FaultID.trim(),
    label: item.FaultDescription.trim(),
  }));
}

export function useVmSites(customerNumber?: string) {
  const [vmSites] = useAsyncData<
    (customerNumber?: string) => Promise<VmSiteSqlData[] | undefined>
  >(vmSitesApi.getVmSites, customerNumber);

  return (
    vmSites?.map((vmSite) => ({
      value: vmSite.TXSITE.trim(),
      label: vmSite.TXSITENAME.trim(),
    })) || []
  );
}

export function useAccessories() {
  const [accessories] = useAsyncData<
    () => Promise<{ AccessoryID: string; AccessoryDescription: string }[]>
  >(supportTicketsApi.getAccessories);

  return accessories?.map((accessory) => ({
    value: `${accessory.AccessoryID.trim()}`,
    label: `${accessory.AccessoryID.trim()} - ${accessory.AccessoryDescription.trim()}`,
  }));
}
