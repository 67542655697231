export const Paths = {
  AUTH: 'auth',
  LOG_IN: 'log-in',
  SIGN_UP: 'sign-up',
  FORGOT_PASSWORD: 'forgot-password',
  CHANGE_PASSWORD_SUCCESS: 'change-password-success',
  REGISTRATION_SUCCESS: 'registration-success',
  EQUIPMENT: 'equipment',
  JOBS: 'jobs',
  SUPPORT_TICKETS: 'support-tickets',
  CHECK_YOUR_EMAIL: 'check-your-email',
  RESET_PASSWORD_CONFIRM: 'password/reset/confirm/:uid/:token',
  SHOW_TICKET: 'show-ticket/:ticketNumber',
  SHOW_EQUIPMENT: 'show-equipment/:serialNumber',
  CREATE_TICKET: 'create-ticket',

  APP: {
    INDEX: '/app',
    PROFILE: {
      INDEX: 'profile',
      EDIT_PROFILE: 'edit-profile',
      PASSWORD: 'password',
      VM_SITES: 'vm-sites',
    },
    EQUIPMENT: '/app/equipment',
    JOBS: '/app/jobs',
    SUPPORT_TICKETS: '/app/support-tickets',
    SHOW_TICKET: '/app/show-ticket',
    SHOW_EQUIPMENT: '/app/show-equipment',
    CREATE_TICKET: '/app/create-ticket',
  },
};
