import { Typography } from 'antd';
import { GoBackButton } from '../../components/GoBackButton/GoBackButton';
import { TicketForm } from '../../features/support-tickets/TicketForm/TicketForm';
import S from './styles/create-ticket.module.css';

export const CreateTicket = () => {
  return (
    <div className={S.container}>
      <GoBackButton />
      <Typography.Title level={4}>{'Add new ticket'}</Typography.Title>
      <TicketForm />
    </div>
  );
};
